import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Overlays from './../Overlays/index';
import BackgroundImage from './../BackgroundImage/index';

import returnPaddingMultiplierStylesObj from '../../util/returnPaddingMultiplerStylesObj.js'

const maxWidth = "1440px"

const OneColumnLayout = (props) => {
  const { params } = props;

  //set up indicator if large header is being rendered in one column layout component
  const componentNameToHideBackgroundImageOn = "LargeHeader"
  const componentNameBeingRenderedInOneColumnComponent = (
    props.rendering.placeholders &&
      props.rendering.placeholders["ppm-one-column-main"] &&
      props.rendering.placeholders["ppm-one-column-main"].length > 0 &&
      props.rendering.placeholders["ppm-one-column-main"][0].componentName ?
      props.rendering.placeholders["ppm-one-column-main"][0].componentName : ""
  )

  //first usage - one column layout and header - fixed header height for mobile view port
  const displayBackgroundImageFixedHeight = componentNameBeingRenderedInOneColumnComponent === componentNameToHideBackgroundImageOn

  //set up var for background color
  const backgroundColor = params && params.backgroundColor ? '#' + params.backgroundColor : "";

  //set up the style object
  const paddingStyle = returnPaddingMultiplierStylesObj(params)

  //check if user selected full or max width for background image and overlay
  const componentWidthOption = params && params.layoutComponentWidthOption ? params.layoutComponentWidthOption : "";
  const useMax_1440_Width = componentWidthOption === "max-width-1440"

  //check if user selected ful or max width for background color
  const backgroundColorWidthOption = params && params.backgroundColorWidthOption ? params.backgroundColorWidthOption : "";
  const useBackGroundColorAt_1440_Width = backgroundColorWidthOption === "max-width-1440"
  //set up a placeholder to get style base on user selection of background color width and overl/image width
  let layoutComponentStyle
  let backgroundColorStyle

  //if the user tries to set full width on image/overlay and max width on background color
  //the background color will be set to full width - unsupported option
  if (!useMax_1440_Width && useBackGroundColorAt_1440_Width) {
    layoutComponentStyle = {
      backgroundColor
    }
  }
  //if both the background color and the overlay/image are at max 1440 width set the background style on the layoutcomponent style obj and set max width with margin auto
  else if (useMax_1440_Width && useBackGroundColorAt_1440_Width) {
    layoutComponentStyle = {
      backgroundColor,
      maxWidth: maxWidth,
      margin: "auto"
    }
    //if just the background color width was set to max width set appropriate styles
  } else if (useBackGroundColorAt_1440_Width) {
    backgroundColorStyle = {
      maxWidth: maxWidth,
      margin: "auto",
      backgroundColor
    }
    //otherwise if it's just max width for image/overlay and not background color - set different component styles
  } else if (useMax_1440_Width) {
    layoutComponentStyle = {
      maxWidth: maxWidth,
      margin: "auto"
    }
    backgroundColorStyle = {
      backgroundColor
    }
    //if both are set to full width let the component size to max size and set the background color on the second div
  } else {
    layoutComponentStyle = {
      backgroundColor
    }
  }

  //BACKGROUND IMAGE AND FOCAL POINT
  // define back image
  const backImage = params && params.backgroundImage ? params.backgroundImage : null;
  // define focal point
  const focalPoint = params && params.focalPoint ? params.focalPoint :
    null;

  //OVERLAY Settings
  const overlay = params && params.overlay ? params.overlay : null;
  const overlayColor = params && params.overlayColor ? params.overlayColor : null;
  const leftOverlayColor = params && params.leftOverlayColor ? params.leftOverlayColor : null;
  const rightOverlayColor = params && params.rightOverlayColor ? params.rightOverlayColor : null;

  // logic to remove padding if nested component is an image component
  let hasComponent = (
    props.rendering.placeholders &&
    props.rendering.placeholders["ppm-one-column-main"] &&
    props.rendering.placeholders["ppm-one-column-main"][0] &&
    props.rendering.placeholders["ppm-one-column-main"][0].componentName
  );

  // use provided height settings if this layout component has no placeholder component
  const backgroundImageDesktopHeight = params && params.backgroundImageDesktopHeight && !hasComponent ? params.backgroundImageDesktopHeight : null,
    backgroundImageTabletHeight = params && params.backgroundImageTabletHeight && !hasComponent ? params.backgroundImageTabletHeight : null,
    backgroundImageMobileHeight = params && params.backgroundImageMobileHeight && !hasComponent ? params.backgroundImageMobileHeight : null;

  const imageComponentName = "IndividualImage"
  let hasImageComponent = (
    props.rendering.placeholders &&
    props.rendering.placeholders["ppm-one-column-main"] &&
    props.rendering.placeholders["ppm-one-column-main"][0] &&
    props.rendering.placeholders["ppm-one-column-main"][0].componentName === imageComponentName
  );

  const fullWidthClass = 'fullWidth',
    noPaddingClass = "p-0";

  let containerClass = '',
    optionalClassToRemovePadding = '';

  if (hasImageComponent) {
    optionalClassToRemovePadding = noPaddingClass
    if (componentWidthOption === 'full-width') {
      containerClass = fullWidthClass;
    }
  }

  return (
    <section className="ppm-one-column-layout-component-container" style={backgroundColorStyle}>
      <div style={layoutComponentStyle}>
        <BackgroundImage
          displayBackgroundImageFixedHeight={displayBackgroundImageFixedHeight}
          backgroundImageDesktopHeight={backgroundImageDesktopHeight}
          backgroundImageTabletHeight={backgroundImageTabletHeight}
          backgroundImageMobileHeight={backgroundImageMobileHeight}
          backgroundPositionDesktop={{
            x: params && params.backgroundImagePositionX ? params.backgroundImagePositionX : undefined,
            y: params && params.backgroundImagePositionY ? params.backgroundImagePositionY : undefined
          }}
          backgroundPositionTablet={{
            x: params && params.backgroundImageTabletPositionX ? params.backgroundImageTabletPositionX : undefined,
            y: params && params.backgroundImageTabletPositionY ? params.backgroundImageTabletPositionY : undefined
          }}
          backgroundPositionMobile={{
            x: params && params.backgroundImageMobilePositionX ? params.backgroundImageMobilePositionX : undefined,
            y: params && params.backgroundImageMobilePositionY ? params.backgroundImageMobilePositionY : undefined
          }}
          backgroundImage={backImage}
          focalPoint={focalPoint}
          routeData={props.routeData}>
          <Overlays
            props={props}
            children={props.children}
            overlay={overlay}
            leftOverlayColor={leftOverlayColor}
            rightOverlayColor={rightOverlayColor}
            overlayColor={overlayColor}
            overlayElement='ppm-one-column-main'
            overlaySubElement='ppm-one-column-main'>
            <div className={`container ${containerClass}`}>
              <div className="row" id="ppm-one-column-main">
                <div className={`col-12 ${optionalClassToRemovePadding}`} style={paddingStyle}>
                  <Placeholder name="ppm-one-column-main" {...props} />
                </div>
              </div>
            </div>
          </Overlays>
        </BackgroundImage>
      </div>
    </section>
  );
}

export default OneColumnLayout;
