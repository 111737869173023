import React from 'react';
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import './linkedTitleRichTextLink.css';
import SubNavigationLink from '../SubNavigationLink/index.js';

const LinkedTitleRichTextLink = ({ fields }) => {
  if(!fields || !fields.targetLink){
    return null;
  }
  return (
    <div className="linked-title-rich-text__link-item">
      <div className="linked-title-rich-text__link-item-name-wrapper">
        <SubNavigationLink 
          fields={fields}
          linkTextClass="heading-2"
        />
      </div>
      <RichText className="linked-title-rich-text__link-item-text body-copy" field={fields.bodyText} />
    </div>
  );
}

export default LinkedTitleRichTextLink;