import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { useMediaQuery } from 'react-responsive'
import './LargeHeader.css';

const mobileBreakpoint = 576;

const LargeHeader = props => {
	const defaultTitleColor = '000000',
				defaultSubtitleColor = '000000',
				params = props.params,
				fields = props.fields,
				titleColorDesktop = params && params.titleColor ? params.titleColor : defaultTitleColor,
				titleColorMobile = params && params.titleColorMobile ? params.titleColorMobile : defaultTitleColor,
				subTitleColorDesktop = params && params.subTitleColorDesktop ? params.subTitleColor : defaultSubtitleColor,
				subTitleColorMobile = params && params.subTitleColorMobile ? params.subTitleColorMobile : defaultSubtitleColor,
				isMobile = useMediaQuery({ query: `(max-width: ${mobileBreakpoint}px)` });

	const titleColor = isMobile ? titleColorMobile : titleColorDesktop,
				subTitleColor = isMobile ? subTitleColorMobile : subTitleColorDesktop;

	let textWrapperStyle;
	return (
		<div className='large-header'>
		    <div className="large-header-text-wrapper" style={textWrapperStyle}>
			    <div style={{ color: `#${titleColor}` }} className="large-header-title display-1">
					<Text field={fields.title} />
				</div>
				<div style={{ color: `#${subTitleColor}` }} className="large-header-subtitle">
					<h3>
						<Text field={fields.subtitle}/>
					</h3>
				</div>
			</div>
		</div>
	);
}

export default LargeHeader;
