import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import data from './buttonData.js';
import './ButtonsAndLinks.css';

const ButtonsAndLinks = (props) => (
  <React.Fragment>
    
    <div className="row">
      <div className="col-12">
        <h2 className="buttons__page-title">Buttons / Links </h2>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <h4 className="buttons__section-title">BUTTON</h4>
      </div>
    </div>
    <div className="row buttons__button-columns">
      <div className="col-12">
        <ButtonColumn buttonData={data.buttons.primary}/>
        <ButtonColumn buttonData={data.buttons.secondary}/>
        <ButtonColumn buttonData={data.buttons.tertiary}/>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <h4 className="buttons__section-title">TEXT BUTTON</h4>
      </div>
    </div>
    <div className="row just-content-start">
      <div className="col-12">
        
        {data.labels.map((label, idx) => {
          return (
            <Label text={label.text}
                   className={label.className}
                   id={idx}
                   key={idx}
            />
          )
        })}

      </div>
    </div>

  </React.Fragment>
);

const ButtonColumn = (props) => {
  const containerClass = props.buttonData.normal.text.toLowerCase() ==='tertiary' ? 'button-container tertiary-background' : 'button-container';
  return (
    <div className={containerClass}>
      <Button className={props.buttonData.normal.className} text={props.buttonData.normal.text}/>
      <Button className={props.buttonData.hover.className} text={props.buttonData.hover.text}/>
      <Button className={props.buttonData.selected.className} text={props.buttonData.selected.text}/>
      <Button className={props.buttonData.disabled.className} text={props.buttonData.disabled.text}/>
    </div>
  )
}
const Button = (props) => {
  return (
    <span className={props.className}>
      {props.text}
    </span>
  )
}

const Label = (props) => {
  return (  
    <div className="row">
      <div className="col-12">
        <span className={props.className}>
          {props.text}
        </span>
      </div>
    </div>
  )
}
export default ButtonsAndLinks;
