//util
import {canUseDom} from './canUseDom'

//move to config
const globalPadding = 15
const paddingReductionAmountForMobile = 2
const mobileBreakPoint = 576

const returnPaddingMultiplierStylesObj = (params, componentType) => {
    
    let windowIsBelowMobileBreakPoint = false
    if(canUseDom){
        //check the size of the window
        let windowWidth = window.innerWidth
        //if smaller than mobile breakpoint - return styles without left right padding
        if(windowWidth < mobileBreakPoint){
            windowIsBelowMobileBreakPoint = true
        }
    }
    
    let topPaddingDefault
    let rightPaddingDefault
    let bottomPaddingDefault
    let leftPaddingDefault
    
    //set up var to indicate whether mobile reduction of top bottom padding should be applier
    let applyTopBottomPaddingReduction = false
    switch(componentType){
        case "cta":
            topPaddingDefault = "3"
            rightPaddingDefault = "0"
            bottomPaddingDefault = "3"
            leftPaddingDefault = "3"
            applyTopBottomPaddingReduction = true
            break
        case "twoColumnLayout":
            topPaddingDefault = "1"
            rightPaddingDefault = "1"
            bottomPaddingDefault = "1"
            leftPaddingDefault = "1"
            applyTopBottomPaddingReduction = true
            break
        case "threeColumnLayout":
            topPaddingDefault = "1"
            rightPaddingDefault = "1"
            bottomPaddingDefault = "1"
            leftPaddingDefault = "1"
            applyTopBottomPaddingReduction = true
            break
        case "spacer":
            topPaddingDefault = "1"
            rightPaddingDefault = "0"
            bottomPaddingDefault = "1"
            leftPaddingDefault = "0"
            applyTopBottomPaddingReduction = true
            break
        default:
            topPaddingDefault = "1"
            rightPaddingDefault = "1"
            bottomPaddingDefault = "1"
            leftPaddingDefault = "1"
            //the default applies to the two column layout and should not have top bottom padding reduction
            break
    }

    //set up top and bottom padding multipliers
    const topPaddingMultiplier = params && params.topPaddingMultiplier ? params.topPaddingMultiplier : topPaddingDefault;
    const rightPaddingMultiplier = params && params.rightPaddingMultiplier ? params.rightPaddingMultiplier : rightPaddingDefault;
    const bottomPaddingMultiplier = params && params.bottomPaddingMultiplier ? params.bottomPaddingMultiplier : bottomPaddingDefault;
    const leftPaddingMultiplier = params && params.leftPaddingMultiplier ? params.leftPaddingMultiplier : leftPaddingDefault;
    
    //return style object based on mobile breakpoint
    if(windowIsBelowMobileBreakPoint && applyTopBottomPaddingReduction){
        //for mobile reduce top bottom padding by half 
        return {
            paddingTop: handleZeroPadding(topPaddingMultiplier, true),
            paddingBottom: handleZeroPadding(bottomPaddingMultiplier, true)
        }
    }else{
        //only return left and right padding if window is above mobile breakpoint
        return {
            paddingTop: handleZeroPadding(topPaddingMultiplier),
            paddingBottom: handleZeroPadding(bottomPaddingMultiplier),
            paddingLeft: handleZeroPadding(leftPaddingMultiplier),
            paddingRight: handleZeroPadding(rightPaddingMultiplier)
        }
    }
}

const handleZeroPadding = (paddingMultiplier, reducePaddingByHalf = false) => {
    //had to check for zero because the id or name or folder name of 0 conflicted with the color 000000
    //TODO Check on how to resolve this - not crucial but would be nice

    //if the layout is below mobile threshold and the layout components padding multiplier is greater than 1
    //divide the total padding by 2
    let multiplier = reducePaddingByHalf && paddingMultiplier > 1 ? 
        parseInt(paddingMultiplier, 10) / paddingReductionAmountForMobile : 
        parseInt(paddingMultiplier, 10)
    return paddingMultiplier === 'zero' ? 
        "0px" : 
        `${globalPadding * multiplier}px`
}

export default returnPaddingMultiplierStylesObj