import React from 'react';
import {Link} from 'react-router-dom'
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import truncateString from '../../util/truncateString'

const numberToTruncateVideoDescBy = 35
const numberToTruncateArticleDescBy = 50

const templateToCardTitleNameLookUp ={
  "article-insight-route" : "Insight",
  "video-insight-route" : "Video"
}
// Set up spacing between dash to use muliplier for even above and below spacing
const baseMultiplier = 40
const titleStyles = {
  top: `${baseMultiplier}px`,
  left: "40px"
}
const afterTitleStylesString = `top: ${baseMultiplier + 20}px;`
const descStyles = {
  top: `${(baseMultiplier * 3.5) - 4}px`,
  left: "40px"
}

const GridCard = ({cardData, playIcon, defaultCardImage, jssCodeFirstState, pageEditing, visibilityClass}) => {
  if(!pageEditing){
    //these are the key strings for the returned object
    const title_fieldName = "template"
    const tileTitle_fieldName = "tileTitle"
    const description_fieldName = "tileDescription"
    const imageSource_fieldName = "tileImage"
    const linkUrl_fieldName = "url"

    //set up placeholder vars to be assigned if data exists for them
    let cardTitle, titleDescription, imageSource, imageAltText, linkUrl, tileTitle

    //set up bool to indicate if play icon should be added
    let itemIsVideoType = false

    //assign all the placeholders their data if it exists 
    Object.keys(cardData).forEach(item => {
      switch(item){
        case title_fieldName:
          cardTitle = templateToCardTitleNameLookUp[cardData[item].name]
          itemIsVideoType = cardTitle === "Video"
          break
        case description_fieldName:
          titleDescription = cardData[item].value
          break
        case imageSource_fieldName:          
          const imagePath = cardData[item] && cardData[item].src ? cardData[item].src : defaultCardImage.value.src
          if(jssCodeFirstState){
            imageSource = imagePath.replace('/sitecore/shell/-/media/ppmamerica/', '').replace('.ashx','.jpg');
          }else{
            //TODO this replace may be unnecessary
            imageSource = imagePath ? imagePath.replace('/sitecore/shell/-/media/ppmamerica/', '') : defaultCardImage.value.src;
          }
            //set the alt tag if it exits otherwise use the default image alt tag
            imageAltText = cardData[item] && cardData[item].alt ? cardData[item].alt : defaultCardImage.value.alt
          break
        case linkUrl_fieldName:
          linkUrl = cardData[item]
          break
        case tileTitle_fieldName:
          //in case it's news set the tile title 
          tileTitle = cardData[item].value
          break
        default: 
          break
      }
    })
    return  (
<React.Fragment>
  <style dangerouslySetInnerHTML={{
      __html: [
        `.ppm-grid-card-title:after{
          ${afterTitleStylesString}
          content: "";
          height: 1px;
          width: 24px;
          background-color: white;
          display: block;
          position: absolute;
          left: 0;
        }`
        ].join('\n')
      }}>
  </style>
  <div className="ppm-dynamic-content-card-container">
    <a href={linkUrl}
      className={`${visibilityClass ? visibilityClass : ""} ppm-grid-card-link`}>
        <img 
          className="ppm-grid-card-image"
          src={imageSource} 
          alt={imageAltText}>
        </img>
        <div 
          className="ppm-grid-card-overlay">
        </div>
        <h4 
          className="ppm-grid-card-title"
          style={titleStyles}>
          {tileTitle === "" ? cardTitle :  tileTitle}
        </h4>
        <h3
          className="ppm-grid-card-desc"
          style={descStyles}>
          {itemIsVideoType ? 
            truncateString(titleDescription, numberToTruncateVideoDescBy) : 
            truncateString(titleDescription, numberToTruncateArticleDescBy)}
        </h3>
        {itemIsVideoType ?  
        <img 
          className="ppm-grid-card-play-icon"
          src={playIcon ? playIcon.value.src : ''}
          alt={playIcon ? playIcon.value.alt : ''}>
        </img>
        : null
        }
    </a>
  </div>
</React.Fragment>
      )
  }else{
    const styleForLabel = {
      fontWeight: "bold"
    }
    const noAttributeIndicator = "N/A"
    const name = cardData.name ? cardData.name : noAttributeIndicator
    const url = cardData.url ? cardData.url : noAttributeIndicator
    const templateName = cardData.template &&  cardData.template.name ?
    cardData.template.name : noAttributeIndicator
    // if there is no valid data source for the card then render NA
    const tileTitleJss = cardData.tileTitle && cardData.tileTitle.jss ? cardData.tileTitle.jss : noAttributeIndicator
    const tileTitleJssExists = tileTitleJss !== noAttributeIndicator
    //set up vars to handle desc jss
    const tileDescJss = cardData.tileDescription && cardData.tileDescription.jss ? cardData.tileDescription.jss : noAttributeIndicator
    const tileDescJssExists = tileDescJss !== noAttributeIndicator
    //set up vars to handle image jss
    const tileImageJss = cardData.tileImage && cardData.tileImage.jss ? cardData.tileImage.jss : noAttributeIndicator
    const tileIMageJssExists = tileImageJss !== noAttributeIndicator
    return (
    <div style={{height: "100%"}}>
      <div style={styleForLabel}>Card's Page Name</div>
      <div>{name}</div>
      <div style={styleForLabel}>Card's Page Url</div>
      <div>{url}</div>
      <div style={styleForLabel}>Card's Title Based on Page Template</div>
      <div>{templateToCardTitleNameLookUp[templateName]}</div>
      <div style={styleForLabel}>Card's Title from Page Data</div>
      {tileTitleJssExists ? <Text field={tileTitleJss}></Text> : noAttributeIndicator}
      <div style={styleForLabel}>Card's Description from Page Data</div>
      {tileDescJssExists ? <Text field={tileDescJss}></Text> : noAttributeIndicator}
      <div style={styleForLabel}>Card's Image from Page Data</div>
      {tileIMageJssExists ? <Image field={cardData.tileImage.jss}></Image> : noAttributeIndicator}
    </div>
    )
  }
}

export default GridCard;
