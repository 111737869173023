import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const RichTextSubcategoryListItemLink =  ({item, index}) => {
  if(!item.fields || !item.fields.targetLink){
    return null;
  }
  return  <Link key={index} field={item.fields.targetLink} />
};

export default RichTextSubcategoryListItemLink;
