import React from 'react';
import { Text, Image, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import {Link} from 'react-router-dom'
import './SmallHeader.css';

const SmallHeader = props => {
	if(!props.fields){
		return null
	}
	//set up default title color based on editing
	let defaultTitleAndDashColor
	const inEditingMode = props && props.context ? props.context.pageEditing : false
	if(inEditingMode){
		defaultTitleAndDashColor = "000000"
	}else{
		defaultTitleAndDashColor = "ffffff"
	}
	const params = props.params ? props.params : null;
	const titleColor = params && params.titleColor ? params.titleColor : defaultTitleAndDashColor;
	const uid = props ? "index-" + props.rendering.uid.replace(/\D+/g, '') : null;

	//get the breadcrumbs array from the context object
	let breadCrumbs = 	props.context && 
			props.context.breadcrumbs && props.context.breadcrumbs.breadcrumbs ? props.context.breadcrumbs.breadcrumbs : []
	//set up mock breakcrumbs if in code first mode
	const testLocalWithNoBreakCrumbs = false
	if(props.routeData.databaseName === "available-in-connected-mode" && !testLocalWithNoBreakCrumbs){
		breadCrumbs = [
			{
				displayName: "Home",
				path: "/"
			},
			{
				displayName: "Investment Solutions",
				path: "/investment-solutions"
			},
			{
				displayName: "Strategies",
				path: "/investment-solutions/strategies"
			},
			{
				displayName: "Fixed Income",
				path: "/investment-solutions/strategies/fixed-income"
			}
		]
	}
	let showUnderline = false
	let parentPage 
	//now check if there if we're on a url that's are level three or four
	if(breadCrumbs && breadCrumbs.length > 2){
		//if there is a parent url to display then set the style bool
		showUnderline = true
		//set the parentPage index to use
		parentPage = breadCrumbs[breadCrumbs.length - 2] 
	}
	//set up visibility style based on breadcrumb visibility bool
	const visibilityStyle = {
		visibility: showUnderline ? "visible" : "hidden"
	}
	// const {caretIcon} = props.fields
	const caretIcon = (
	<svg 
		className="ppm-breadcrumbs-caret" 
		role="img" 
		aria-hidden="true" 
		focusable="true" 
		width="10" 
		height="24" 
		viewBox="0 0 10 24"//<min-x> <min-y> <width> <height>
		>
		<g xmlns="http://www.w3.org/2000/svg">
			<path 
				transform="rotate(-180,3.8049245,6.0746485)"
				d="M 6.447,5.668 1.94,1.161 A 0.55083618,0.55083618 0 0 0 1.161,1.94 l 4.12,4.12 -4.121,4.152 a 0.55013317,0.55013317 0 1 0 0.781,0.775 L 6.45,6.446 A 0.55,0.55 0 0 0 6.448,5.668 Z"
				fill={`#${titleColor}`}>
			</path>
		</g>
	</svg>
	)
	const breadCrumbsAreDisplay = breadCrumbs.length > 2
	return (
		<>
			<div className="small-header">
				<div className="small-header-wrapper">
					{breadCrumbsAreDisplay ? (
						<React.Fragment>
							<div id={uid} className="small-header-title" style={visibilityStyle}>
								<span 
									className="ppm-breadcrumbs-caret">
									<Link to={parentPage ? parentPage.path : "/"}>
										{caretIcon}
									</Link>
								</span>
								<h2 className="ppm-breadcrumbs-title" style={{ color: `#${titleColor}` }}>
									<Link 
										className="ppm-breadcrumbs-title-link"
										to={parentPage ? parentPage.path : "/"}>
										{parentPage ? parentPage.displayName : ""}
									</Link>
								</h2>
							</div>

							<style dangerouslySetInnerHTML={{
								__html: [
									"#" + uid + '.small-header-title::after {',
									'  background-color: #' + titleColor,
									'}'
									].join('\n')
								}}>
							</style>
						</React.Fragment>
					): null}
					<div style={{ color: `#${titleColor}` }} className={breadCrumbsAreDisplay ? `display-2 negMargin` : `display-2`}>
						<Text field={props.fields.title}/>
					</div>

				</div>
			</div>
			{inEditingMode ? <h5>Content defaults to white, rendered black for experience editor only.</h5> : null}
			{/* For view with breadcrumbs */}
			{/* For Desktop */}
			{/* <div style={{top:"0px", width:"100px", height:"45px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"65px", width:"100px", height:"45px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"112px", width:"100px", height:"45px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"210px", width:"100px", height:"45px",position:"absolute", backgroundColor: "green"}}></div> */}
			{/* For Tablet */}
			{/* <div style={{top:"0px", width:"100px", height:"36.5px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"56px", width:"100px", height:"36.5px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"94px", width:"100px", height:"36.5px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"152px", width:"100px", height:"36.5px",position:"absolute", backgroundColor: "green"}}></div> */}
			{/* For Mobile */}
			{/* <div style={{top:"0px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"46px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"78px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"128px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div> */}
			
			{/* For view with no breadcrumbs */}
			
			{/* <div style={{top:"0px", width:"100px", height:"58px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"110px", width:"100px", height:"58px",position:"absolute", backgroundColor: "green"}}></div> */}
			
			{/* For Tablet */}
			
			{/* <div style={{top:"0px", width:"100px", height:"37.5px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"60px", width:"100px", height:"37.5px",position:"absolute", backgroundColor: "green"}}></div> */}
			
			{/* For Mobile */}
			
			{/* <div style={{top:"0px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div>
			<div style={{top:"54px", width:"100px", height:"30.15px",position:"absolute", backgroundColor: "green"}}></div> */}
		</>
	);
}

export default SmallHeader;
