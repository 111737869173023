import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

import './PpmModal.css'

export class PpmModal extends React.Component{
    includeCheckboxValue = this.props.params && this.props.params.includeCheckbox ? this.props.params.includeCheckbox : ""
    includeCheckbox = this.includeCheckboxValue === "include-checkbox" 
    footerStyle = {
        marginTop: "0em",
        marginBottom: "0em"
    }
    state = {
        checkBoxIsDisabled: this.includeCheckbox ? true : false
    }
    updateCheckBox = () => {
        this.setState({
            checkBoxIsDisabled: !this.state.checkBoxIsDisabled
        })
    }
    handleAcceptClick = () => {
        this.setState({
            checkBoxIsDisabled: !this.state.checkBoxIsDisabled
        })
    } 
    render(){
        if(!this.includeCheckbox){
            this.footerStyle = {
                marginTop: "2em",
                marginBottom: "2em"
            }
        }
        const visibilityClass = this.props.open ? "visibilityClass" : ""
        const {checkBoxIsDisabled} = this.state 
        return (
<div className={`ppm-modal-container ${visibilityClass}`} >
    <div className={`ppm-modal-dialog ${visibilityClass}`}>
        <div className="ppm-modal-header-dismiss-button-div">
            <button 
                onClick={this.props.onClose}
                aria-label="Dismiss Dialog"
                className="ppm-modal-header-dismiss-button">
            </button>
        </div>
        <div className="ppm-modal-header">
            <div className="modalHeading">
                <Text field={this.props.fields.modalHeading}></Text>
            </div>
        </div>
        <div className="ppm-modal-body-container">
            <RichText field={this.props.fields.modalBodyRichText}></RichText>
        </div>
        { this.includeCheckbox ? <div className="ppm-modal-checkbox-container">
            <input 
                type="checkbox" 
                className="ppm-modal-checkbox" 
                onClick={this.handleAcceptClick}></input>
            <h5 className="ppm-modal-checkbox-text">
                <RichText field={this.props.fields.checkBoxText}></RichText> 
            </h5>
        </div> : null }
        <div className="ppm-modal-footer" style={this.footerStyle}>
            <div className="ppm-modal-footer-left-button-div">
                <button
                    className="ppm-modal-footer-left-button"
                    onClick={this.props.onConfirm}
                    type="button"
                    disabled={checkBoxIsDisabled}
                    aria-label="accept">
                    <Text field={this.props.fields.modalAcceptCta}></Text>
                </button>
            </div>
            <div className="ppm-modal-footer-right-button-div">
                <button
                    className="ppm-modal-footer-right-button"
                    onClick={this.props.onCancel}
                    type="button"
                    aria-label="decline">
                    <Text field={this.props.fields.modalDeclineCta}></Text>
                </button>
            </div>
        </div>
    </div>
</div>
) 
    }
}

export default PpmModal

