import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './fundGroup.css';

const FundGroup = (props) => {
  if (!props.fields) {
    return null;
  }
  return (
    <div className="fund-group">
      <h2 className="fund-group__title">
        <Text field={props.fields.title} />
      </h2>
      <Placeholder
        name="ppm-fund"
        {...props}
        iconPdf={props.fields.iconPdf}
        iconExcel={props.fields.iconExcel}
      />
    </div>
  )
}

export default FundGroup;
