import React from "react";
import returnPaddingMultiplierStylesObj from "../../util/returnPaddingMultiplerStylesObj.js";
import returnBorderMultiplierStyleObj from "../../util/returnBorderMultiplierStylesObj.js";

const Spacer = (props) => {
  const includeBorder = props.params && props.params.includeBorder === "include-border"
  // console.log(`Got value of ${includeBorder} for include border`)
  const paddingStyle = returnPaddingMultiplierStylesObj(props.params, "spacer"),
    borderHeight = returnBorderMultiplierStyleObj(props.params),
    topStyle = {
      height: paddingStyle.paddingTop,
    },
    bottomStyle = {
      height: paddingStyle.paddingBottom,
    },
    marginStyle = {
      marginLeft: paddingStyle.paddingLeft,
      marginRight: paddingStyle.paddingRight,
    },
    borderStyle = {
      ...marginStyle,
      border: 0,
      ...borderHeight,
      borderColor:
        props.params && props.params.borderColor
          ? `#${props.params.borderColor}`
          : "inherit",
      borderStyle:
        props.params && props.params.borderStyle
          ? props.params.borderStyle
          : "solid",
    };

  return (
    <React.Fragment>
      <div style={topStyle}></div>
      {includeBorder ? <div style={borderStyle}></div> : null}
      <div style={bottomStyle}></div>
    </React.Fragment>
  );
};

export default Spacer;
