const generateJssQuery = (path) => {
  return `
  {
    item(path: "${path}") {
        name
        url
        displayName
       template{
         name
       }
        ... on Contenttiledata {
          tileTitle {
            jss
          }
          tileDescription {
            jss
          }
          tileImage {
            jss
          }
        }
      }
    }
`
}
export default generateJssQuery