import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import "./typography.css";
import desktopData from "./desktopData.js";
import mobileData from "./mobileData.js";

const PpmBreakPoint = 576; //move into config or somewhere

class Typography extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      let mediaWatch = window.matchMedia(`(max-width: ${PpmBreakPoint}px)`);
      this.state = {
        data: desktopData,
        mobileViewPort: this.returnResponsiveStyles(mediaWatch)
      };
      mediaWatch.addListener(this.applyResponsiveStyles);
    } else {
      this.state = {
        data: desktopData
      };
    }
  }

  //two methods to check for mobile due to console errors on set state before render is triggered
  applyResponsiveStyles = query => {
    this.setState({
      data: query.matches ? mobileData : desktopData
    });
  };
  returnResponsiveStyles = query => {
    return query.matches ? mobileData : desktopData;
  };
  componentDidMount = () => {
    this.setState({
      data: window.matchMedia(`(max-width: ${PpmBreakPoint}px)`).matches
        ? mobileData
        : desktopData
    });
  };

  render() {
    return (
      <div>
        <h2 className="page-title">{this.state.data.pageTitle}</h2>
        <Section tableData={this.state.data.displayRow} />
        <Section tableData={this.state.data.headingRow} />
        <Section tableData={this.state.data.paragraphRow} />
      </div>
    );
  }
}

const Section = ({ tableData }) => {
  const rowData = tableData.rowData.map((row, index) => {
    return (
      <div className="row align-items-center" key={index}>
        <DisplayCol
          className="col-sm-3"
          title={row.title}
          propValuePairs={row.props}
        />
        <div className="col-sm-9">
          <div className="demo_text_container">
            <span className={row.demo_text_class + ' demo_text'}>{row.demo_text}</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="row">
        <div className="col-12 section-header">{tableData.headerText}</div>
      </div>
      {rowData}
    </div>
  );
};

const DisplayCol = props => {
  const pairs = props.propValuePairs.map((pair, index) => {
    return (
      <PropertyValuePair
        key={index}
        propertyName={pair.propertyName}
        propertyValue={pair.propertyValue}
      />
    );
  });
  return (
    <div className={props.className}>
      <div className="row">
        <div className="col-sm">
          <span className="col-header">{props.title}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">{pairs}</div>
      </div>
    </div>
  );
};

const PropertyValuePair = props => {
  return (
    <div className="row">
      <div className="col">
        <span className="property_name">{props.propertyName}</span>
      </div>

      <div className="col">
        <span className="property_value">{props.propertyValue}</span>
      </div>
    </div>
  );
};
export default Typography;
