import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Overlays from './../Overlays/index';
import BackgroundImage from './../BackgroundImage/index';

import returnPaddingMultiplierStylesObj from '../../util/returnPaddingMultiplerStylesObj.js'

const maxWidthNumber = 1440
const maxWidth = `${maxWidthNumber}px`

const ThreeColumnLayout = (props) => {
  const { params } = props;
  //setup the padding multiplier object
  const paddingStyle = returnPaddingMultiplierStylesObj(params, "threeColumnLayout")

  const componentWidthOption = params && params.layoutComponentWidthOption ? params.layoutComponentWidthOption : "";
  const useMax_1440_Width = componentWidthOption === "max-width-1440"

  const mainBackgroundColor = params && params.mainBackgroundColor ? params.mainBackgroundColor : "";
  const leftColumnBackgroundColor = params && params.leftColumnBackgroundColor ? params.leftColumnBackgroundColor : "";
  const middleColumnBackgroundColor = params && params.middleColumnBackgroundColor ? params.middleColumnBackgroundColor : "";
  const rightColumnBackgroundColor = params && params.rightColumnBackgroundColor ? params.rightColumnBackgroundColor : "";

  let layoutComponentStyle

  if(useMax_1440_Width){
    layoutComponentStyle = {
      backgroundColor: `#${mainBackgroundColor}`,
      maxWidth: maxWidth,
      margin: "auto"
    }
  }else{
    layoutComponentStyle = {
      backgroundColor: `#${mainBackgroundColor}`,
    }
  }

  const ppmThreeColumnContainerMaxWidthStyle = {
    maxWidth: `${maxWidthNumber}px`,
    margin: "auto"
  }
  // Set up column styles and padding if they do have background style
  
  //set up variables for the column styles
  let leftColumnBackgroundColorStyle
  let middleColumnBackgroundColorStyle
  let rightColumnBackgroundColorStyle
  
  //COMPONENT BACKGROUND COLOR
  //set up bools to indicate whether column has styles
  const leftColumnHasBackgroundColor = leftColumnBackgroundColor !== "" 
  const middleColumnHasBackgroundColor = middleColumnBackgroundColor !== ""
  const rightColumnHasBackgroundColor = rightColumnBackgroundColor !== "" 
  
  if(leftColumnHasBackgroundColor){
    leftColumnBackgroundColorStyle = { 
      backgroundColor: `#${leftColumnBackgroundColor}`
    }
  }

  if(middleColumnHasBackgroundColor){
    middleColumnBackgroundColorStyle = { 
      backgroundColor: `#${middleColumnBackgroundColor}`
    }
  }

  if(rightColumnHasBackgroundColor){
    rightColumnBackgroundColorStyle = { 
      backgroundColor: `#${rightColumnBackgroundColor}`
    }
  }

  //BACKGROUND IMAGE AND FOCAL POINTS FOR LAYOUT COMPONENT
  // Left side
  // define back image
  const leftBackImage = params && params.leftBackgroundImage ? params.leftBackgroundImage : null;
  // define focal point
  const leftFocalPoint = params && params.leftFocalPoint ? params.leftFocalPoint : null;

  // Middle side
  // define back image
  const middleBackImage = params && params.middleBackgroundImage ? params.middleBackgroundImage : null;
  // define focal point
  const middleFocalPoint = params && params.middleFocalPoint ? params.middleFocalPoint : null;

  // Right side
  // define back image
  const rightBackImage = params && params.rightBackgroundImage ? params.rightBackgroundImage : null;
  // define focal point
  const rightFocalPoint = params && params.rightFocalPoint ? params.rightFocalPoint : null;

  //OVERLAY OPTIONS

  //use original params to get values 
  const overlay = params && params.overlay ? params.overlay : null;
  const overlayColor = params && params.overlayColor ? params.overlayColor : null;

  //set up vars for overlay
  const leftOverlay = params && params.leftOverlay ? params.leftOverlay : overlay;
  const middleOverlay = params && params.middleOverlay ? params.middleOverlay : overlay;
  const rightOverlay = params && params.rightOverlay ? params.rightOverlay : overlay;
  
  //overlay color options

  //global column colors - used if individual left and right column colors aren't provided
  const leftOverlayColor = params && params.leftOverlayColor ? params.leftOverlayColor : overlayColor;
  const middleOverlayColor = params && params.middleOverlayColor ? params.middleOverlayColor : overlayColor;
  const rightOverlayColor = params && params.rightOverlayColor ? params.rightOverlayColor : overlayColor;

  //individual column left right color options
  const leftColumnLeftOverlayColor = params && params.leftColumnLeftOverlayColor ? params.leftColumnLeftOverlayColor : leftOverlayColor;
  const leftColumnRightOverlayColor = params && params.leftColumnRightOverlayColor ? params.leftColumnRightOverlayColor : leftOverlayColor;
  const middleColumnLeftOverlayColor = params && params.middleColumnLeftOverlayColor ? params.middleColumnLeftOverlayColor : middleOverlayColor;
  const middleColumnRightOverlayColor = params && params.middleColumnRightOverlayColor ? params.middleColumnRightOverlayColor : middleOverlayColor;
  const rightColumnLeftOverlayColor = params && params.rightColumnLeftOverlayColor ? params.rightColumnLeftOverlayColor : rightOverlayColor;
  const rightColumnRightOverlayColor = params && params.rightColumnRightOverlayColor ? params.rightColumnRightOverlayColor : rightOverlayColor;

  return (
<section className="ppm-three-column-layout-component-container" style={layoutComponentStyle}>
  <div style={ppmThreeColumnContainerMaxWidthStyle}>
    <div className="ppm-layout-component-three-column-container">
      <div style={leftColumnBackgroundColorStyle}>
        <BackgroundImage 
          backgroundImage={leftBackImage} 
          focalPoint={leftFocalPoint}
          routeData={props.routeData}>
          <Overlays 
            props={props}
            children={props.children} 
            overlay={leftOverlay}
            overlayColor ={leftOverlayColor}
            leftOverlayColor={leftColumnLeftOverlayColor}
            rightOverlayColor={leftColumnRightOverlayColor}
            overlayElement='ppm-three-column-left' 
            overlaySubElement='ppm-three-column-left'>
            <div 
              id="ppm-three-column-left" 
              className={`ppm-layout-component-three-column-left`} 
              >
              <div className="container">
                <div className="row">
                  <div className="col-12" style={paddingStyle}>
                    <Placeholder name="ppm-three-column-left" {...props} />
                  </div>
                </div>
              </div>
            </div>
          </Overlays>
        </BackgroundImage>
      </div>
      <div style={middleColumnBackgroundColorStyle}> 
        <BackgroundImage 
          backgroundImage={middleBackImage} 
          focalPoint={middleFocalPoint}
          routeData={props.routeData}>
          <Overlays 
            props={props}
            children={props.children} 
            overlay={middleOverlay}
            overlayColor ={middleOverlayColor}
            leftOverlayColor={middleColumnLeftOverlayColor}
            rightOverlayColor={middleColumnRightOverlayColor}
            overlayElement='ppm-three-column-middle' 
            overlaySubElement='ppm-three-column-middle'>
            <div 
              id="ppm-three-column-middle" 
              className={`ppm-layout-component-three-column-left`} 
            >
              <div className="container">
                <div className="row">
                  <div className="col-12" style={paddingStyle}>
                    <Placeholder name="ppm-three-column-middle" {...props} />
                  </div>
                </div>
              </div>
            </div>
          </Overlays>
        </BackgroundImage>
      </div>
      <div style={rightColumnBackgroundColorStyle}> 
        <BackgroundImage 
          backgroundImage={rightBackImage} 
          focalPoint={rightFocalPoint}
          routeData={props.routeData}>
            <Overlays 
            props={props}
            children={props.children} 
            overlay={rightOverlay}
            overlayColor ={rightOverlayColor}
            leftOverlayColor={rightColumnLeftOverlayColor}
            rightOverlayColor={rightColumnRightOverlayColor}
            overlayElement='ppm-three-column-right' 
            overlaySubElement='ppm-three-column-right'>
            <div 
              id="ppm-three-column-right" 
              className={`ppm-layout-component-three-column-left`} 
              >
              <div className="container">
                <div className="row">
                  <div className="col-12" style={paddingStyle}>
                    <Placeholder name="ppm-three-column-right" {...props} />
                    </div>
                  </div>
                </div>
              </div>
            </Overlays>
          </BackgroundImage>
        </div>
      </div>
    </div>
  </section>
  );
}

export default ThreeColumnLayout;