import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const FundList = (props) => (
  <div>
    <Placeholder
      name="ppm-fund-group"
      {...props}
    />
  </div>
);

export default FundList;
