import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import "./footerStyles.css";

const Footer = ({fields, context}) => {
    if (!fields || !context) {
        return null;
    }
    const {
        logo,
        street,
        suite,
        cityZip,
        phone,
        copyright,
        contactLink,
        privacyLink,
        termsLink,
        socialLink,
        linkedinLogo,
        linkedinLink
    } = fields;

    return (
        <React.Fragment>
            <footer  
                id="jss-footer">
                {/* <div className="buffer"></div> */}
                <div className="footer-content">
                    <div className="buffer"></div>
                    <div className="row footer-container">
                        <div className="footer-logo col-6 order-1 col-sm-4 order-sm-1 col-md-3 order-md-1 col-lg-2 order-lg-1 col-xl-1 order-xl-1">
                            <Image className="ppm-logo col-auto col-sm-auto col-md-auto col-lg-auto col-xl-auto" media={logo} />
                        </div>

                        <div className="address col-11 order-2 col-sm-auto order-sm-3 col-md-12 order-md-3 col-lg-8 offset-lg-1 order-lg-2 col-xl-9 order-xl-2">
                            <ul>
                                <li><Text field={street} /></li>
                                <li><Text field={suite} /></li>
                                <li><Text field={cityZip} /></li>
                                <li><Text field={phone} /></li>
                            </ul>
                        </div>
                        <div className="social-icons col-10 order-3 col-sm-2 offset-sm-6 order-sm-2 col-md-2 offset-md-6 order-md-2 col-lg-auto order-lg-3 offset-lg-0 col-xl-1 order-xl-3 offset-xl-0">
                        {context.pageEditing ? "Click to edit URL " : ""}
                            <Link field={linkedinLink}>
                                {context.pageEditing ? null : <Image media={linkedinLogo} />}
                            </Link>
                        </div>
                    </div>
                    <div className="row footer-container buffer">
                        <div className="copyright col-12 order-2 col-sm-12 order-sm-2 col-md-10 order-md-2 col-lg-5 order-lg-1 col-xl-5 order-xl-1">
                            <h6><Text field={copyright} /></h6>
                        </div>
                        <div className="footer-links col-12 order-1 col-sm-12 order-sm-1 col-md-10 order-md-1 col-lg-7 order-lg-2 col-xl-7 offset-xl-0 order-xl-2">
                            <ul>
                                <li><Link field={contactLink}/></li>
                                <li><Link field={privacyLink}/></li>
                                <li><Link field={termsLink}/></li>
                                <li><Link field={socialLink}/></li>
                            </ul>
                        </div>
                    </div>                        
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer;
