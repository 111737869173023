const mockedResponse = {
  "data": {
    "item": {
      "children": [
        {
          "name": "article1",
          "url": "/insight/article1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "2019 Asset Class Outlooks"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-article.ashx",
            "alt": "Article 1"
          }
        },
        {
          "name": "article2",
          "url": "/insight/article2",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Caps: A Small Debt Produces A Debtor; A Large One, An Enemy"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-article2.ashx",
            "alt": "Article 2"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big Small Enough To Think Big Small Enough To Think Big Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "video-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big Small Enough To Think Big Small Enough To Think Big Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        },
        {
          "name": "video1",
          "url": "/insight/video1",
          "template": {
            "name": "article-insight-route"
          },
          "tileTitle": {
            "value": ""
          },
          "tileDescription": {
            "value": "Small Enough To Think Big"
          },
          "tileImage": {
            "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-news2.ashx",
            "alt": "Video 1"
          }
        }
      ]
    }
  }
 }

  export default mockedResponse