import React from "react";
import "./focalpoints.css";
import config from "../../temp/config";
//util
import { canUseDom } from "../../util/canUseDom";
import { useMediaQuery } from 'react-responsive'

/**
 * Tablet device viewport
 * min device width: 576 - set it so it borders mobile breakpoint
 * max device width: 800 - covers samsung and ipad tablets
 */
const mobileBreakPoint = 576; //TODO Move to config test build
const tabletMaxBreakPoint = 800;
const tabletMinBreakPoint = 576;

const decorateConditionallyWithHeight = (height, styleObjToDecorate) => {
  //set as default param value in sitecore manifest file for one column layout component 
  //sitecore\definitions\components\OneColumnLayout.sitecore.js
  const autoString = 'auto'

  //Only apply height if it's defined - layout components will have an undefined or null height
  if (height) {
    styleObjToDecorate.height = height === autoString ? height : `${height}px`
  }
}
const decorateConditionallyWithPosition = (x, y, styleObjToDecorate) => {
  if (x) {
    styleObjToDecorate.backgroundPositionX = `${x}%`
  }
  if (y) {
    styleObjToDecorate.backgroundPositionY = `${y}%`
  }
  return styleObjToDecorate
}

function getBackgroundImageStyle({ props, isMobileWidth, isTabletWidth, isDesktopWidth }) {
  let backgroundDesktopStyle = {}
  let backgroundTabletStyle = {}
  let backgroundMobileStyle = {}
  if (isDesktopWidth) {
    decorateConditionallyWithHeight(
      props.backgroundImageDesktopHeight,
      backgroundDesktopStyle
    )
    decorateConditionallyWithPosition(
      props.backgroundPositionDesktop ? props.backgroundPositionDesktop.x : undefined,
      props.backgroundPositionDesktop ? props.backgroundPositionDesktop.y : undefined,
      backgroundDesktopStyle
    )
    return backgroundDesktopStyle
  } else if (isTabletWidth) {
    decorateConditionallyWithHeight(
      props.backgroundImageTabletHeight,
      backgroundTabletStyle
    )
    decorateConditionallyWithPosition(
      props.backgroundPositionTablet ? props.backgroundPositionTablet.x : undefined,
      props.backgroundPositionTablet ? props.backgroundPositionTablet.y : undefined,
      backgroundTabletStyle
    )
    return backgroundTabletStyle
  } else if (isMobileWidth) {
    decorateConditionallyWithHeight(
      props.backgroundImageMobileHeight,
      backgroundMobileStyle
    )
    decorateConditionallyWithPosition(
      props.backgroundPositionMobile ? props.backgroundPositionMobile.x : undefined,
      props.backgroundPositionMobile ? props.backgroundPositionMobile.y : undefined,
      backgroundMobileStyle
    )
    return backgroundMobileStyle
  }
}

const BackgroundImage = props => {
  const isMobileWidth = useMediaQuery({ query: `(max-width: ${mobileBreakPoint}px)` })
  const isTabletWidth = useMediaQuery({ minWidth: tabletMinBreakPoint + 1, maxWidth: tabletMaxBreakPoint - 1 });
  const isDesktopWidth = useMediaQuery({ query: `(min-width: ${tabletMaxBreakPoint}px)` });

  // define background image
  const imagePath = props && props.backgroundImage ? String(props.backgroundImage) : null;
  let backgroundImageStyle = getBackgroundImageStyle({ props, isMobileWidth, isTabletWidth, isDesktopWidth });

  // console.log(backgroundPosition);
  let focalPoint = props && props.focalPoint ? props.focalPoint : null;
  let backImage,
    backImageSize;
  //split into backround/title sections if in large header
  if (props.displayBackgroundImageFixedHeight && isMobileWidth) {
    backImageSize = {
      backgroundSize: `auto 300px`
    }
    //If rendering in mobile, never position the background image in bottom of component if image is fixed height - first usage - one column layout and header
    if (focalPoint && focalPoint.indexOf("bottom") > -1) {
      focalPoint = focalPoint.replace("bottom", "top")
    }
    //If author selected center focal point default to top left
    if (focalPoint && focalPoint.indexOf("center") > -1) {
      focalPoint = "top-center"
    }
  } else {
    backImageSize = {
      backgroundSize: `cover`
    }
  }
  if (props.routeData.databaseName === "available-in-connected-mode") {
    backImage = imagePath
      ? "url('" +
      imagePath.replace(
        "/sitecore/media library/" + config.jssAppName,
        ""
      ) +
      ".png')"
      : null;
  } else {
    backImage = imagePath
      ? "url('" +
      imagePath.replace("/sitecore/media library/", "/-/jssmedia/") +
      ".ashx')"
      : null;
  }

  const style = {
    backgroundImage: backImage,
    ...backgroundImageStyle,
    ...backImageSize
  };
  return (
    <div
      className={`${focalPoint} backgroundImage`}
      // Inline styles take precedence so a focal point class will be overriden by css in js x and y positions
      style={style}
    >
      {props.children}
    </div>
  )
}

export default BackgroundImage;
