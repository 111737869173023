const mockedResponse = {
  "data": {
    "item": {
      "name": "article1",
      "url": "/insight/article1",
      "displayName": "Article 1",
      "template": {
        "name": "video-insight-route"
      },
      "tileTitle": {
        "value": ""
      },
      "tileDescription": {
        "value": "2019 Asset Class Outlooks"
      },
      "tileImage": {
        "src": "/sitecore/shell/-/media/ppmamerica/assets/insights/insights-article.ashx",
        "alt": "Article 1"
      }
    }
  }
 }

  export default mockedResponse