import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import './teamBioCard.css';

const TeamBioCard = ({ fields }) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 team-bio-card">
      <div className="row">
        <div className="col-4 col-md-12">
          <Link 
            field={fields.targetLink}
            className="team-bio-card__image-link"
          >
            <Image
              media={fields.profileImage}
              className="team-bio-card__profile-image"
            />
          </Link>
        </div>
        <div className="col-8 col-md-12">
        <h5>
          <Link 
            field={fields.targetLink}
            className="team-bio-card__name"
          />
        </h5>
          <p 
            className="body-copy team-bio-card__role"
          >
            <Text field={fields.role}/>
          </p>
          </div>
      </div>
    </div>
  )
}

export default TeamBioCard;
