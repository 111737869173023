import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const RichTextSubcategoryListItem = ({item, index}) => {
  if(!item.fields){
    return null;
  }
  return(
      <Text key={index} field={item.fields.listItem} />
  )
}


export default RichTextSubcategoryListItem;
