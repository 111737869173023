import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import "./OneColumnRichText.css";
import superscriptStyle from "../../globalStylesCssInJs/superscriptStyle.js"

const defaultTitleColor = "000000"

const OneColumnRichText = ({ fields, params }) => {
  // just in case if there is no datasource item passed to this component
  if (!fields) {
    return null;
  }

  const {
    title1,
    title2,
    textarea,
    superScriptTitle1,
    superScriptTitle2
  } = fields;

  const title1Color = params && params.title1Color ? params.title1Color : defaultTitleColor,
        title2Color = params && params.title2Color? params.title2Color : defaultTitleColor,
        // include-super-script or no-super-script - defautl to no super script
        superScriptTitle1Option = params && params.includeSuperScriptTitle1 ? params.includeSuperScriptTitle1 : "no-super-script",
        includeSuperScriptTitle1 = superScriptTitle1Option === "include-super-script",
        superScriptTitle2Option = params && params.includeSuperScriptTitle2 ? params.includeSuperScriptTitle2 : "no-super-script",
        includeSuperScriptTitle2 = superScriptTitle2Option === "include-super-script"
  
  return (
    <div
      className="one-column-rich-text__wrapper"
    >
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 one-column-rich-text__title-container">
              <h2 className="one-column-rich-text__title">
                <span
                  style={{
                    color: `${title1Color ? "#" + title1Color : ""}`
                  }}
                >
                  <Text field={title1} />
                  {superScript(superScriptTitle1, includeSuperScriptTitle1)}
                </span>
                <br />
                <span
                  style={{
                    color: `${title2Color ? "#" + title2Color : ""}`
                  }}
                >
                  <Text field={title2} />
                  {superScript(superScriptTitle2, includeSuperScriptTitle2)}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <RichText
            className="one-column-rich-text__textarea body-copy"
            field={textarea}
          />
        </div>
      </div>
    </div>
  );
};

//func for returning superscript jsx
const superScript = (superscriptField, showSuperScriptField) => {
  return showSuperScriptField ? 
    //If a class is needed for the superscript item use this
    // <span className="one-column-rich-text__title___superscript">
    <span style={superscriptStyle}>
      <Text field={superscriptField}></Text>
    </span> : null
}

export default OneColumnRichText;
