import React from 'react'
import ReactDOM from "react-dom";
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import PpmModal from './PpmModal'
import {canUseDom} from '../../util/canUseDom'

//for testing purposes set to half a minute
// const numberOfMillisecondsInADay = 30000
const numberOfMillisecondsInADay = 86400000
const modalAcceptedTimeKey = `modalAcceptedTime`
class DisclosurePopup extends React.Component {
  constructor(props){
    super(props)
    if(!props.fields){
      return null
    }  
    
    //default agreement accepted indicator to false
    this.userAcceptedAgreementWithinDayAgo = false
    let timeUserAcceptedAgreement = canUseDom ? Number(localStorage.getItem(modalAcceptedTimeKey)) : 0
    let timeAtPageLoad = Date.now()
    if(canUseDom){
      //if user hasn't accepted the agreement within a day ago then show the modal
      let timeSinceLastAcceptance = timeAtPageLoad - timeUserAcceptedAgreement
      this.userAcceptedAgreementWithinDayAgo = timeSinceLastAcceptance < numberOfMillisecondsInADay
    }
    
    //show the modal if they haven't accepted the agreement within a day ago
    this.state = {
      modalIsOpen: !this.userAcceptedAgreementWithinDayAgo
    }
  }
  showDisclosureModal = (event) => {
    this.disableMainSiteVertScroll()
    this.setState({
      modalIsOpen: true
    })
  }
  disableMainSiteVertScroll = () => {
    if(canUseDom){
      //disable scroll on the body
      document.getElementsByTagName("body")[0].classList.add("noVertScroll")
    }
  }
  enableMainSiteVertScroll = () => {
    if(canUseDom){
      document.getElementsByTagName("body")[0].classList.remove("noVertScroll")
    }
  }
  onConfirm = () => {
    this.enableMainSiteVertScroll()
    this.setState({modalIsOpen: false})
    localStorage.setItem(modalAcceptedTimeKey, Date.now())
  }
  onCloseOrDecline = () => {
    if(canUseDom){
      if(window.history.length > 2){
        //if there is history go back to the previous page
        window.history.back()
      }else{
        //if they come directly to this page with the disclosure - like an email link send them to the home page
        window.location.href = window.location.origin
      }
    }
  }
  onCancel = () => {
    this.enableMainSiteVertScroll()
    this.setState({modalIsOpen: false})
    this.onCloseOrDecline()
  }
  onClose = () => {
    this.enableMainSiteVertScroll()
    this.setState({modalIsOpen: false})
    this.onCloseOrDecline()
  }

  render(){
    if(canUseDom){
      this.rootSelector = document.getElementById("root-modal");
      this.container = this.rootSelector.children[0]
    }
    if(this.props.context.pageEditing){
      //if in editing mode - show the content fields for the modal
      const styleForLabel = {
        fontWeight: "bold"
      }
      return <div>
        <div style={styleForLabel}>Modal Title</div>
        <Text field={this.props.fields.modalHeading}></Text>
        <div style={styleForLabel}>Modal Body</div>
        <RichText field={this.props.fields.modalBodyRichText}></RichText>
        <div style={styleForLabel}>Checkbox Text (Only Displayed If Selected in Dropdown)</div>
        <RichText field={this.props.fields.checkBoxText}></RichText>
        <div style={styleForLabel}>Modal Accept Button</div>
        <Text field={this.props.fields.modalAcceptCta}></Text>
        <div style={styleForLabel}>Modal Decline Button</div>
        <Text field={this.props.fields.modalDeclineCta}></Text>
      </div>
    }else if(this.state.modalIsOpen && canUseDom){
      //create poral expects two params, first the content you want to portal and second where you want the portal to exist
      return ReactDOM.createPortal(
      <PpmModal 
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        open={this.state.modalIsOpen}
        {...this.props}/>, 
        this.container
        )
    }else{
      return null
    }
  }
}

export default DisclosurePopup;
