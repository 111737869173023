import React from 'react'
import { NavLink } from 'react-router-dom';
import $ from 'jquery'

const cnMobileLookUp = {
    //first
    // firstLevelLinks: "ppm-nav-first-level-link-mobile", TODO REmove confirm not used
    firstLevelLinksLi: "ppm-nav-mobile-first-level-li",
    firstLevelLinkUl: "ppm-nav-mobile-first-level-links-ul",
    firstLevelRightArrow: "ppm-nav-mobile-first-level__right-arrow",
    //second
    secondLevelLinksUl: "ppm-nav-mobile-second-level-links-ul",
    secondLevelLinkLi: "ppm-nav-mobile-second-level-li",
    secondLevelLeftArrow: "ppm-nav-mobile-second-level-title-li-arrow",
    secondLevelRightArrow: "ppm-nav-mobile-second-level__right-arrow",
    //third
    thirdLevelLinksUl: "ppm-nav-mobile-third-level-links-ul",
    thirdLevelLinkLi: "ppm-nav-mobile-third-level-li",
    thirdLevelLeftArrow: "ppm-nav-mobile-third-level-title-li-arrow",
    thirdLevelRightArrow: "ppm-nav-mobile-third-level__right-arrow",
    //fourth
    fourthLevelLinksUl: "ppm-nav-mobile-fourth-level-links-ul",
    fourthLevelLinkLi: "ppm-nav-mobile-fourth-level-li",
    fourthLevelLeftArrow: "ppm-nav-mobile-fourth-level-title-li-arrow",
    //common
    backGroundColorWhiteClass: "background-color-white",
    allStylesForLinksUl: "ppm-mobile-page-ul-container",
    allStylesForLinksLi: "ppm-nav-mobile-page-li"
  }

const mobileNavPageGap = "13vw"
const mobileNavPageGapHidden = "100vw"
const clickTouchEventString = "click touch"

class PpmMobileNavigation extends React.Component{
    isClosed = true
    componentDidMount(){
        if(this.props.canUseDom){
            //bind a function on scroll to hide the search container if the user scrolls at all
            window.addEventListener('scroll', this.hideSearchOnScroll);
              
            // change padding for website content for mobile display\
            var bodyContent = document.getElementById("ppm-main-website-content-container")
            bodyContent.style.paddingTop = "80px"
            
            //set up click handles for the burger menu
            var trigger = $('#ppm-burger-menu')
                trigger.click(function () {
                    alterBurgerMenuToCross();      
                });
                const alterBurgerMenuToCross = () => {
                    //in case the user opened the search container
                    if(this.searchIsOpen){
                        this.hideSearchContainer()
                        this.hideCrossShowSearch()
                    }
                    if (this.isClosed === false) {          
                        this.jqSelectors.overlay.hide();
                        trigger.removeClass('is-open');
                        trigger.addClass('is-closed');
                        
                        //when the x gets clicked hide any currently shown mobile nav pages
                        $('#ppm-nav-mobile-first-level-links-ul')[0].style.left = mobileNavPageGapHidden;
                        
                        this.hideLevelTwoMobilePage()
                        this.hideLevelThreeMobilePage()
                        this.hideLevelFourMobilePage()
                        this.handleSearchPageSearchBarFuncitonality()
                        //set is closed to false since we just opened the nav
                        this.isClosed = true;
                    } else {   
                        this.jqSelectors.overlay.show();
                        this.setUpGoToSecondLevelArrows()
                        trigger.removeClass('is-closed');
                        trigger.addClass('is-open');
                        let firstLevelMobilePage = $('#ppm-nav-mobile-first-level-links-ul')[0]
                        firstLevelMobilePage.style.left = mobileNavPageGap
                        //set is closed to false
                        this.isClosed = false;
                    }
                }
            $('[data-toggle="offcanvas"]').click(function () {
                $('#wrapper').toggleClass('toggled');
            });  
            this.setUpJquerySelectors()
            this.setUpSearchIconClickHandler()  
            this.handleSearchPageSearchBarFuncitonality()
        }   
    }
    handleSearchPageSearchBarFuncitonality = () => {
        if(this.props.onSearchResultsPage){            
            this.addSearchTermToSearchBar()
            this.hideSearchShowCross()
            this.showSearchContainer()
        } 
    }
    addSearchTermToSearchBar = () => {
        //initialize search term to place in search bar incase it was manually removed from url
        let plusReplacesWithSpacesSearchTerm = ""
        if(window.location.search.indexOf('=') > -1){
            //replace + with spaces for user friendly display of search term
            plusReplacesWithSpacesSearchTerm = this.props.searchTermUtil()
        }
        // set the search term as the text in the search bar
        document.getElementById("ppm-nav-mobile-search-input").value = plusReplacesWithSpacesSearchTerm
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        let onNewPage = prevProps.currentPage !== this.props.currentPage
        if(onNewPage && this.isClosed == false){
            //collapse the mobile nav if navigating to a new page
            //simulate click on nav menu collapse handler
            $('#ppm-burger-menu').click()
        }
        //since render doesn't use state, exlicitly call set up method
        //if last page was on search results and now new page isn't search results
        if(!prevProps.onSearchResultsPage && 
            prevProps.currentPage !== this.props.currentPage){
            this.hideCrossShowSearch()
            //since we're going to a new page we also need to change the padding that's added on scroll
            this.hideSearchContainer(true)  
        }
      }
    //track whether the search container is open
    searchIsOpen = false
    //hide search on scroll
    hideSearchOnScroll = (event) => {
        if(this.searchIsOpen && !this.props.onSearchResultsPage){
            //hide the search container
            this.hideCrossShowSearch()
            this.hideSearchContainer()
            //execute blur on input so keyboard is hidden
            $("#ppm-nav-mobile-search-input").blur();
        }
        //when user scrolls apply drop shadow to navigation container
        const distance = window.pageYOffset || document.documentElement.scrollTop
        
        if(distance > 0){
            //add drop shadow to nav bar
            $("#ppm-mobile-navigation-container").addClass("ppm-mobile-nav-dropShadow")
        }else{
            //remove it if they scrolled back to the top
            $("#ppm-mobile-navigation-container").removeClass("ppm-mobile-nav-dropShadow")
        }
    }
    //general functions
    setUpJquerySelectors = () => {
        this.jqSelectors = {
            burgerTop: $('.ppm-burger-menu-top'),
            burgerBottom: $('.ppm-burger-menu-bottom'),
            overlay: $('#ppm-overlay'),
            searchIcon: $("#ppm-nav-mobile-search-icon-list-item"),
            searchCross: $("#ppm-nav-mobile-search-cross-list-item"),
            searchContainer: $("#ppm-nav-mobile-search-container")
        }
    }
    setCrossToWhite = (setToWhite) => {
        if(setToWhite){
            //add background color of white class to top and bottom of cross using background color marked as important to override original black background
            this.jqSelectors.burgerTop.addClass(cnMobileLookUp.backGroundColorWhiteClass)
            this.jqSelectors.burgerBottom.addClass(cnMobileLookUp.backGroundColorWhiteClass)
        }else{
            this.jqSelectors.burgerTop.removeClass(cnMobileLookUp.backGroundColorWhiteClass)
            this.jqSelectors.burgerBottom.removeClass(cnMobileLookUp.backGroundColorWhiteClass)
        }
    }
    //search icon functions
    setUpSearchIconClickHandler = () => {
        this.jqSelectors.searchIcon.on(clickTouchEventString, () => {
            this.hideSearchShowCross()
            this.showSearchContainer()
        })
        this.jqSelectors.searchCross.on(clickTouchEventString, () => {
            this.hideCrossShowSearch()
            this.hideSearchContainer()
        })
    }
    pageYoffsetToStopBumpingDownContentOnSearchShow = 5
    showSearchContainer = () => {
        if(this.props.onSearchResultsPage){            
            this.addSearchTermToSearchBar()
        }   
        this.searchIsOpen = true
        let searchContainer = this.jqSelectors.searchContainer[0]
        searchContainer.firstElementChild.firstElementChild.firstElementChild[0].disabled = false
        searchContainer.style.opacity = "1"
        searchContainer.style.visibility = "visible"
        setTimeout(() => {
            document.getElementById("ppm-nav-mobile-search-input").focus()
        }, 350)
        //add padding to the top of the site
        let siteContent = $("#ppm-main-website-content-container")
        if(window.pageYOffset < this.pageYoffsetToStopBumpingDownContentOnSearchShow){
            siteContent[0].style.paddingTop = "160px" 
        }
        
    }
    hideSearchContainer = (forceChangePadding) => {
        this.searchIsOpen = false
        let searchContainer = this.jqSelectors.searchContainer[0]
        searchContainer.style.opacity = "0"
        searchContainer.style.visibility = "hidden"
        setTimeout(() => {
            //clear out the search value
            searchContainer.firstElementChild.firstElementChild.firstElementChild[0].value = ""
            searchContainer.firstElementChild.firstElementChild.firstElementChild[0].disabled = true
        }, 350)
        let siteContent = $("#ppm-main-website-content-container")
        if(forceChangePadding || window.pageYOffset < this.pageYoffsetToStopBumpingDownContentOnSearchShow){
            siteContent[0].style.paddingTop = "80px"
        }
    }
    hideSearchShowCross = () => {
        let search = this.jqSelectors.searchIcon[0]
        let cross = this.jqSelectors.searchCross[0]
        //hide the search icon
        search.style.opacity = "0"
        search.style.visibility = "hidden"
        //show the cross
        cross.style.opacity = "1"
        cross.style.visibility = "visible"
    
    }
    hideCrossShowSearch = () => {
        let search = this.jqSelectors.searchIcon[0]
        let cross = this.jqSelectors.searchCross[0]
        //hide the search icon
        search.style.opacity = "1"
        search.style.visibility = "visible"
        //show the cross
        cross.style.opacity = "0"
        cross.style.visibility = "hidden"
    }
    //LEVEL 2 Funcs
    setUpGoToSecondLevelArrows = () => {
        $(`.${cnMobileLookUp.firstLevelRightArrow}`)
            .on(clickTouchEventString, null, null, (event) => {
            let eventElement = $(event.target)

            //get the container right after the svg element
            let contentForFirstLevelRightArrow = eventElement.next()
            
            //query will always only return one element but it's required to index into it
            let elementToShow = contentForFirstLevelRightArrow[0]
            
            //show the container
            elementToShow.style.left = mobileNavPageGap

            //add background color of white class to top and bottom of cross
            this.setCrossToWhite(true)
            
            //set up go back arrow and title click handlers for newly displayed container
            this.setUpSecondLevelTitleWithArrowClickHandler()

            //set up go to third level arrow click handlers
            this.setUpGoToThirdLevelArrows()
        })
    }
    setUpSecondLevelTitleWithArrowClickHandler = () => {
        let backTolevelTwoArrow = $(`.${cnMobileLookUp.secondLevelLeftArrow}`)
        let backTolevelTwoArrowTitle = $(`.ppm-nav-mobile-second-level-title-li`)
        //go back a step whether they click on the arrow or the title
        backTolevelTwoArrow.on(clickTouchEventString, (event) => {
            this.hideLevelTwoMobilePage()
        })
        backTolevelTwoArrowTitle.on(clickTouchEventString, () => {
            this.hideLevelTwoMobilePage()
        })
    }
    hideLevelTwoMobilePage = () => {
        //hide any second level pages which may be open
        let secondLevelMobilePages = $(`.${cnMobileLookUp.secondLevelLinksUl}`)
        secondLevelMobilePages.each((index, element) => {
            element.style.left = mobileNavPageGapHidden;
        })
        //change the cross back to white
        this.setCrossToWhite(false)
    }
    //LEVEL 3 Funcs
    setUpGoToThirdLevelArrows = () => {
        var trigger = $(`.${cnMobileLookUp.secondLevelRightArrow}`)
        trigger.on(clickTouchEventString, null, null, (event) => {
            //comments same as set up 2nd level
            let clickedSvgArrowForThirdLevelChildren = $(event.target)
            let thirdLevelLinkListContainer = clickedSvgArrowForThirdLevelChildren.next()
            thirdLevelLinkListContainer[0].style.left = mobileNavPageGap
            this.setUpThirdLevelTitleWithArrowClickHandler()
            this.setUpFourthLevelArrows()
        })
    }
    setUpThirdLevelTitleWithArrowClickHandler = () => {
        let levelThreeArrow = $(`.${cnMobileLookUp.thirdLevelLeftArrow}`)
        let levelThreeArrowTitle = $(`.ppm-nav-mobile-third-level-title-li`)
        //go back a step whether they click on the arrow or the title
        levelThreeArrow.on(clickTouchEventString, () => {
            this.hideLevelThreeMobilePage()
        })
        levelThreeArrowTitle.on(clickTouchEventString, () => {
            this.hideLevelThreeMobilePage()
        })
    }
    hideLevelThreeMobilePage = () => {
        //hide any third level pages which may be open
        let thirdLevelMobilePages = $(`.${cnMobileLookUp.thirdLevelLinksUl}`)
        thirdLevelMobilePages.each((index, element) => {
            element.style.left = mobileNavPageGapHidden;
        })
    }
    //LEVEL 4 Funcs
    setUpFourthLevelArrows = () => {
        //enable third mobile page arrows to go to fourth level
        var trigger = $('.ppm-nav-mobile-third-level__right-arrow')
        trigger.on(clickTouchEventString, null, null, (event) => {
            //comments same as set up 2nd level
            let clickedSvgArrowForFourthLevelChildren = $(event.target)
            let fourthLevelLinkListContainer = clickedSvgArrowForFourthLevelChildren.next()
            fourthLevelLinkListContainer[0].style.left = mobileNavPageGap
            this.setUpFourthLevelTitleWithArrowClickHandler()
            //we're on the fourth level so no more arrows to set up
        })
    }
    setUpFourthLevelTitleWithArrowClickHandler = () => {
        let backTolevelThreeArrow = $(`.${cnMobileLookUp.fourthLevelLeftArrow}`)
        let backToLevelThreeTitle = $(`.ppm-nav-mobile-fourth-level-title-li`)
        //go back a step whether they click on the arrow or the title
        backTolevelThreeArrow.on(clickTouchEventString, () => {
            this.hideLevelFourMobilePage()
        })
        backToLevelThreeTitle.on(clickTouchEventString, () => {
            this.hideLevelFourMobilePage()
        })
    }
    hideLevelFourMobilePage = () => {
        //hide any fourthy level pages which may be open
        let fourthLevelMobilePages = $(`.${cnMobileLookUp.fourthLevelLinksUl}`)
        fourthLevelMobilePages.each((index, element) => {
            element.style.left = mobileNavPageGapHidden;
        })
    }
    render(){
        const searchPlaceholderText = this.props.fields.searchPlaceholderText.value ? this.props.fields.searchPlaceholderText.value : "Search"
        return(
<div id="ppm-mobile-navigation-container">
    <nav id="ppm-nav-mobile">
        <ul id="ppm-nav-mobile-collapsed-ul">
            <li id="ppm-nav-logo-mobile-list-item">
                <NavLink 
                    exact={true}
                    to="/">
                    <img id="ppm-nav-mobile-logo"src={this.props.fields.logo.value.src} alt="PPM Logo"/>
                </NavLink>
            </li>
            <ul id="ppm-nav-mobile-right-icon-list">
                <li id="ppm-nav-mobile-search-icon-list-item">
                    <img id="ppm-nav-mobile-search-icon-image" src={this.props.fields.searchIcon.value.src}></img>
                </li>
                <li id="ppm-nav-burger-menu-mobile-list-item">
                    <button
                        type="button"
                        id="ppm-burger-menu"
                        className="is-closed"
                        data-toggle="offcanvas"
                    >
                        <span className="ppm-burger-menu-top"></span>
                        <span className="ppm-burger-menu-middle"></span>
                        <span className="ppm-burger-menu-bottom"></span>
                    </button>
                </li>
            </ul>
        </ul>
        {/* used to make content behind nav half opacity with background of black */}
        <div id="ppm-nav-mobile-search-container">
            <ul id="ppm-nav-mobile-search-container-ul">
                <li id="ppm-nav-mobile-search-input-list-item">
                    <form action="/search">
                        <input 
                            id="ppm-nav-mobile-search-input"
                            type="text" 
                            name="searchTerm" 
                            placeholder={searchPlaceholderText}>
                        </input>
                    </form>
                </li>
                <li id="ppm-nav-mobile-search-cross-list-item">
                    <span id="ppm-nav-mobile-search-cross-forward-slash"></span>
                    <span id="ppm-nav-mobile-search-cross-back-slash"></span>
                </li>
            </ul>
        </div>
        <div id="ppm-overlay"></div>
        <ul id={cnMobileLookUp.firstLevelLinkUl} className={cnMobileLookUp.allStylesForLinksUl}>
            <li id="ppm-nav-mobile-first-level-search-container">
            </li>
            {   
                this.props.navigationData.navRoot.children.map((navItem, index) => {
                    return navItem.children.length > 0 ?
                    navItem.visible.boolValue ? 
                    <li key={index} className={`${cnMobileLookUp.firstLevelLinksLi} ${cnMobileLookUp.allStylesForLinksLi}`}>
                        <NavLink 
                            exact={true}
                            to={navItem.targetLink.url}>
                            {navItem.targetName.value}
                        </NavLink>
                        <RightArrowGoToSecondLevel navItems={navItem.children} title={navItem.targetName.value}/>
                    </li> : null
                    :
                    navItem.visible.boolValue ? 
                    <li key={index} className={`${cnMobileLookUp.firstLevelLinksLi} ${cnMobileLookUp.allStylesForLinksLi}`}>
                        <NavLink 
                            exact={true}
                            to={navItem.targetLink.url}>
                            {navItem.targetName.value}
                        </NavLink>
                    </li> : null
                })
            }
        </ul>
    </nav>
</div>
        )
    }
}
// second level components
const RightArrowGoToSecondLevel = ({navItems, title}) => {
    let titleContent =  
    <li>
        <ul className="ppm-mobile-page-title-arrow-ul">
            <li>
                <LeftArrowBackToPreviousLevel 
                    classForSvg={cnMobileLookUp.secondLevelLeftArrow}
                />
            </li>
            <li className="ppm-nav-mobile-second-level-title-li ppm-mobile-title">
                {title}
            </li>
        </ul>
    </li>
    return <React.Fragment>
        <RightArrowGoToNextLevel classForSvg={cnMobileLookUp.firstLevelRightArrow}/>
        <ul className={`${cnMobileLookUp.secondLevelLinksUl} ${cnMobileLookUp.allStylesForLinksUl}`}>
        {titleContent}
        {
            navItems.map((navItem, index) => {
                return navItem.children.length > 0 ?
                navItem.visible.boolValue ? 
                <li 
                    key={index} 
                    className={`${cnMobileLookUp.secondLevelLinkLi} ${cnMobileLookUp.allStylesForLinksLi}`}
                    >
                    <NavLink 
                        exact={true}
                        to={navItem.targetLink.url}>
                        {navItem.targetName.value}
                    </NavLink>
                    <RightArrowGoToThirdLevel navItems={navItem.children} title={navItem.targetName.value}/>
                </li> : null
                :
                navItem.visible.boolValue ? 
                <li 
                    key={index} 
                    className={`${cnMobileLookUp.secondLevelLinkLi} ${cnMobileLookUp.allStylesForLinksLi}`}
                    >
                    <NavLink 
                        exact={true}
                        to={navItem.targetLink.url}>
                        {navItem.targetName.value}
                    </NavLink>
                </li> : null
            })
        }
        </ul>
    </React.Fragment>
}
// third level components
const RightArrowGoToThirdLevel = ({navItems, title}) => {
    let titleContent =  
    <li>
        <ul className="ppm-mobile-page-title-arrow-ul">
            <li>
                <LeftArrowBackToPreviousLevel 
                classForSvg={cnMobileLookUp.thirdLevelLeftArrow}
            />
            </li>
            <li className="ppm-nav-mobile-third-level-title-li ppm-mobile-title">{title}</li>
        </ul>
    </li>
     return <React.Fragment>
        <RightArrowGoToNextLevel classForSvg={cnMobileLookUp.secondLevelRightArrow} whiteFill={true}/>
        <ul className={`${cnMobileLookUp.thirdLevelLinksUl} ${cnMobileLookUp.allStylesForLinksUl}`}>
        {titleContent}
        {  
            navItems.map((navItem, index) => {
                return navItem.children.length > 0 ?
                navItem.visible.boolValue ?
                <li 
                    key={index} 
                    className={`${cnMobileLookUp.thirdLevelLinkLi} ${cnMobileLookUp.allStylesForLinksLi}`}
                    >
                    <NavLink 
                        exact={true}
                        to={navItem.targetLink.url}>
                        {navItem.targetName.value}
                    </NavLink>
                    <RightArrowGoToFourthLevel 
                        navItems={navItem.children} 
                        title={navItem.targetName.value}
                    />
                </li> : null
                :
                navItem.visible.boolValue ? <li 
                    key={index} 
                    className={`${cnMobileLookUp.thirdLevelLinkLi} ${cnMobileLookUp.allStylesForLinksLi}`}
                    >
                    <NavLink 
                        exact={true}
                        to={navItem.targetLink.url}>
                        {navItem.targetName.value}
                    </NavLink>
                </li> : null
                })
        }
        </ul>
    </React.Fragment>
}
const RightArrowGoToFourthLevel = ({navItems, title}) => {
    let titleContent =  
    <li>
        <ul className="ppm-mobile-page-title-arrow-ul">
            <li>
            <LeftArrowBackToPreviousLevel 
                classForSvg={cnMobileLookUp.fourthLevelLeftArrow}/>
            </li>
            <li className="ppm-nav-mobile-fourth-level-title-li ppm-mobile-title">{title}</li>
        </ul>
    </li>
    return <React.Fragment>
        <RightArrowGoToNextLevel 
            classForSvg={cnMobileLookUp.thirdLevelRightArrow} 
            whiteFill={true}
        />
        <ul className={`${cnMobileLookUp.fourthLevelLinksUl} ${cnMobileLookUp.allStylesForLinksUl}`}>
            {titleContent}
            {
            navItems.map((navItem, index) => {
                return navItem.visible.boolValue ? <li 
                    key={index} 
                    className={`${cnMobileLookUp.fourthLevelLinkLi} ${cnMobileLookUp.allStylesForLinksLi}`}
                    >
                    <NavLink 
                        exact={true}
                        to={navItem.targetLink.url}>
                        {navItem.targetName.value}
                    </NavLink>
                </li> : null
                })
        }
        </ul>
    </React.Fragment>   
}
const LeftArrowBackToPreviousLevel = ({classForSvg}) => (
    <svg className={`${classForSvg} ppm-mobile-title-svg-arrow`} 
        role="img" 
        aria-hidden="true" 
        focusable="false" 
        viewBox="0 0 8 13">
        <g xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M6.447 5.668L1.94 1.161a.55.55 0 0 0-.779.779l4.12 4.12-4.121 4.152a.55.55 0 1 0 .781.775L6.45 6.446a.55.55 0 0 0-.002-.778z" 
            fill="white">
            </path>
        </g>
    </svg>
)
const RightArrowGoToNextLevel = ({classForSvg, whiteFill}) => {
    let fillContent = whiteFill ?  "white" : ""
    return (<svg className={`${classForSvg} ppm-mobile-go-to-next-level-right-svg-arrow`} role="img" aria-hidden="true" focusable="false" viewBox="0 0 8 13">
        <g xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M6.447 5.668L1.94 1.161a.55.55 0 0 0-.779.779l4.12 4.12-4.121 4.152a.55.55 0 1 0 .781.775L6.45 6.446a.55.55 0 0 0-.002-.778z" 
            fill={fillContent}
            >
            </path>
        </g>
    </svg>)
}
export default PpmMobileNavigation