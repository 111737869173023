import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './fund.css';
import Ticker from './Ticker'

class Fund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isHover: false,
      isActive: false
    };
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
    this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }
  onMouseEnterHandler(e) {
    this.setState({
      isHover: true
    });
  }

  onMouseLeaveHandler(e) {
    this.setState({
      isHover: false,
      isActive: false
    });
  }

  onMouseDownHandler(e) {
    this.setState({
      isActive: true
    });
  }

  onMouseUpHandler(e) {
    this.setState({
      isActive: false
    });
  }
  onFocusHandler(e) {
    this.setState({
      isActive: true
    });
  }
  onBlurHandler(e) {
    this.setState({
      isActive: false
    });
  }

  onAccordionClick = () => {
    this.setState((prevState, props) => {
      return {
        isOpen: !prevState.isOpen
      };
    });
  }

  render() {
    if (!this.props.fields) {
      return null;
    }
    const { isOpen, isHover, isActive }  = this.state;
    const openClass = isOpen ? 'open' : '',
          hoverClass = isHover ? 'hover' : '',
          activeClass = isActive ? 'active' : '';
    return (
      <div className="fund__wrapper">
        <p 
          className="body-copy fund__accordion-btn"
        >
          <span 
            className={`fund__fund-name ${openClass} ${hoverClass} ${activeClass}`}
            onClick={this.onAccordionClick}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
            onMouseDown={this.onMouseDownHandler}
            onMouseUp={this.onMouseUpHandler}
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
          >
            <Text field={this.props.fields.fundName} />
          </span>
          <svg 
            className={`fund__fund-name-arrow ${openClass} ${hoverClass} ${activeClass}`} 
            role="img" 
            aria-hidden="true" 
            focusable="true" 
            width="6" 
            height="12" 
            viewBox="0 0 8 13"
            onClick={this.onAccordionClick}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
            onMouseDown={this.onMouseDownHandler}
            onMouseUp={this.onMouseUpHandler}
            onFocus={this.onFocusHandler}
            onBlur={this.onBlurHandler}
          >
            <g xmlns="http://www.w3.org/2000/svg">
              <path d="M6.447 5.668L1.94 1.161a.55.55 0 0 0-.779.779l4.12 4.12-4.121 4.152a.55.55 0 1 0 .781.775L6.45 6.446a.55.55 0 0 0-.002-.778z" fill="black"></path>
            </g>
          </svg>
        </p>
        <div 
          className={`fund__panel ${isOpen ? 'open' : ''}`}>
          <Ticker
            {...this.props}
            iconPdf={this.props.fields.iconPdf}
          />
        </div>
      </div>
    )
  }
}

export default Fund;