import React from 'react';
import { Image, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import './individualImage.css';

const IndividualImage = (props) => {
  const {
    fields,
    params,
    numImages
  } = props;
  if (!fields || !params) {
    return null;
  }
  const {
    image,
    targetLink,
    imageLabel,
    imageDescription
  } = fields;
  const {
    imageLabelColor,
    imageDescriptionColor
  } = params;

  const wrapperClass = numImages ? ' individual-image__wrapper col-md-4' : 'individual-image__wrapper';
  const isLinkPresent = targetLink && targetLink.value.href.trim().length > 0;
  const imageLink = {
    value: {
      href: isLinkPresent ? targetLink.value.href : '',
      text: 'edit link'
    }
  };
  const linkEditor = props.context.pageEditing ? (
    <Link 
      field={imageLink}
    />
  ) : null;
  const imageEl = isLinkPresent ? (
    <Link
      field={imageLink}
    >
      <Image
        media={image}
        className="individual-image__image"
      />
    </Link>
  ) : (
    <Image
      media={image}
      className="individual-image__image"
    />
  );
  let imageLabelEl = null;
  if (imageLabel && imageLabel.value && isLinkPresent) {
    imageLabelEl = (
      <h5 className="individual-image__image-label">
        <RichText
            field={imageLabel}
            tag="a"
            href={imageLink.value.href}
            className="individual-image__image-label-link"
            style={{color: imageLabelColor ? `#${imageLabelColor}` : '' }}
          />
      </h5>
    );
  } else if (imageLabel && imageLabel.value) {
    imageLabelEl = (
      <h5 
        className="individual-image__image-label"
        style={{color: imageLabelColor ? `#${imageLabelColor}` : '' }}
      >
        <RichText
          field={imageLabel}
        />
      </h5>
    );
  }
  
  const imageDescriptionEl = imageDescription && imageDescription.value ? (
    <RichText 
        field={imageDescription}
        className="body-copy"
        style={{color: imageDescriptionColor ? `#${imageDescriptionColor}` : '' }}
    />
  ) : null;

  return (
    <div className={wrapperClass}>
      {linkEditor}
      {imageEl}
      {imageLabelEl}
      {imageDescriptionEl}
    </div>
  );
};

export default IndividualImage;
