import React, { Component, useState  } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import config from '../../temp/config';
import query from './navGraphQlQuery'

//ppm components
import PpmDeskTopNavigation from './PpmDeskTopNavigation'
import PpmMobileNavigation from './PpmMobileNavigation'
import navDataHardCoded from './hardCodedNavData'

//util
import {canUseDom} from '../../util/canUseDom'
import searchTermUtil from '../../util/searchTermUtil'

//styles
import './navDesktopStyles.css'
import './navMobileStyles.css'

const PpmBreakPoint = 1024 //move into config or somewhere
const gqlHost = config.graphQLEndpointPath
const apiKey = config.sitecoreApiKey
const keyForNavData = "navigationData"

const searchPageName = "search"

class Navigation extends Component {

  constructor(props){
    super(props)
    if (canUseDom) {
      let mediaWatch = window.matchMedia(`(max-width: ${PpmBreakPoint}px)`)
      this.state = {
        mobileViewPort: mediaWatch.matches,
        navigationData: null,
        onSearchResultsPage: this.props.routeData.name ===  searchPageName
      }
      mediaWatch.addListener(this.applyResponsiveStyles);
      props.history.listen(this.handleRouteChange)
    } else {
      this.state = {
        navigationData: null,
        onSearchResultsPage: false
      }
    }
  }
  useLocalStorage = false
  handleRouteChange = (newLocation, type) => {
    if(newLocation.pathname.indexOf(searchPageName) > -1){
      this.setState({
        onSearchResultsPage: true
      })
    }else{
      this.setState({
        onSearchResultsPage: false
      })
    }
  }
  localStorageOrSessionStorage = () => {
    if(canUseDom){
      return this.useLocalStorage ? localStorage : sessionStorage
    }else{
      return null
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if(canUseDom){
      if(prevProps.location.pathname == window.location.pathname){
        window.scrollTo(0, 0)
      }
    }
  }
  componentDidMount(){
    //if in local jss start mode use hard coded nav data
    if(this.props.routeData.databaseName === "available-in-connected-mode"){
      this.setState({navigationData: navDataHardCoded})
    }else{
      let localStorageNavData = JSON.parse(this.localStorageOrSessionStorage().getItem(keyForNavData)) 
      if(localStorageNavData){
        this.setState({navigationData: localStorageNavData})
        }else{
          //if navigation data isn't in local storage then get it
          axios({
              url: `${gqlHost}?sc_apikey=${apiKey}`,
              method: "post",
              data: {query: query}
          })
          .then((response) => {
              let navData = response.data
              this.setState({
                  navigationData: navData
              })
              //store the navigation data in local storage so it doesn't get called on each page load
              this.localStorageOrSessionStorage().setItem(keyForNavData, JSON.stringify(navData))
          })
          .catch(err => {
              console.log(`
              GraphQL Error Getting Navigation Data: 
              ${err}`)
          })
        }
    }
  }
  applyResponsiveStyles = (query) => {
    //apply mobile styles if query.matches is true
    let resultOfQuery = query.matches
    this.setState({
      mobileViewPort: resultOfQuery
    })
  }
  render() {
    if(!this.state.navigationData || !this.props.routeData.fields){
      return null
    }
    const {
      mobileViewPort, 
      navigationData, 
      onSearchResultsPage
    } = this.state

    let navigation
    
    if(mobileViewPort){
      navigation = <PpmMobileNavigation 
        {...this.props} 
        mobileViewPort={mobileViewPort} 
        navigationData={navigationData.data} 
        onSearchResultsPage={onSearchResultsPage}
        currentPage={this.props.routeData.name}
        canUseDom={canUseDom}
        searchTermUtil={searchTermUtil}
      />
    }else {
      navigation = <PpmDeskTopNavigation
        {...this.props} 
        mobileViewPort={mobileViewPort} 
        navigationData={navigationData.data} 
        onSearchResultsPage={onSearchResultsPage}
        currentPage={this.props.routeData.name}
        canUseDom={canUseDom}
        searchTermUtil={searchTermUtil}
      />
    }
    return (
      <React.Fragment>
        {navigation}
      </React.Fragment>
    );
  }
}


export default withRouter(Navigation);


