import React from 'react';
import { Text, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js'

import BasicsRichText from './../BasicsRichText/index';

import './donutChart.css';

class DonutChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			options: null,
			calculateTotal: null,
			label: null
		};
	}
	componentDidMount() {
		const { fields, params } = this.props,
			uid = this.props.rendering.uid.replace(/\D+/g, ''),
			numberType = params && params.numberTypes ? params.numberTypes : null;
		let fontNumber = 65,
			labelFontNumber = 280,
			total = this.state.calculateTotal,
			label = this.state.label,
			superscriptPlugin = null,
			totalTextSuperscriptPlugin = null,
			cf = this.calcCf(total.toString().length),
			totalTextIncludeSc = params && params.totalTextIncludeSc ? params.totalTextIncludeSc : null;
		let totalSuperScript = '';
		if (numberType) {
			switch (numberType) {
				case "currency":
					totalSuperScript = '$';
					break;
				case "percent":
					totalSuperScript = '%';
					break;
				default:
					totalSuperScript = '';
			}
		}
		var labelPlugin = {
			beforeDraw: function (chart) {
				var width = chart.chart.width,
					height = chart.chart.height,
					ctx = chart.chart.ctx;
				ctx.restore();
				var fontSize = (width / labelFontNumber).toFixed(2);
				ctx.font = fontSize + "em CenturyStd-Book";
				ctx.textBaseline = "top";
				var text = label,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2 - width / 2 + width * 3 / 5;
				ctx.fillStyle = "#008578";
				ctx.fillText(text, textX, textY);
				ctx.save();
			}
		}

		var numberPlugin = {
			beforeDraw: function (chart) {
				var width = chart.chart.width, height = chart.chart.height, ctx = chart.chart.ctx;
				ctx.restore();
				var fontSize = (width / fontNumber).toFixed(2);
				ctx.font = fontSize + "em CenturyStd-Book";
				ctx.textBaseline = "top";
				var text = total,
					textX = Math.round((width - ctx.measureText(text).width) / 2),
					textY = height / 2 - width / 2 + width / 3;
				ctx.fillStyle = "#010101";
				ctx.fillText(text, textX, textY);
				ctx.save();
			}
		};
		let pluginsArray = [labelPlugin, numberPlugin];
		//draw superscript
		if (totalSuperScript.length > 0) {
			superscriptPlugin = {
				beforeDraw: function (chart) {
					var width = chart.chart.width,
						height = chart.chart.height,
						ctx = chart.chart.ctx;
					ctx.restore();
					var fontSize = (width / fontNumber).toFixed(2) / 2;
					ctx.font = fontSize + "em CenturyStd-Book";
					ctx.textBaseline = "top";
					var text = totalSuperScript,
						textX = Math.round((width - ctx.measureText(text).width) / 2) - ctx.measureText(total).width * cf,
						textY = height / 2 - width / 2 + width / 3;
					ctx.fillStyle = "#010101";
					ctx.fillText(text, textX, textY);
					ctx.save();
				}
			}
			pluginsArray.push(superscriptPlugin);
		}
		// draw superscript
		if (totalTextIncludeSc === "include-super-script") {
			totalTextSuperscriptPlugin = {
				beforeDraw: function (chart) {
					var width = chart.chart.width,
						height = chart.chart.height,
						ctx = chart.chart.ctx;
					ctx.restore();
					var fontSize = (width / labelFontNumber).toFixed(2) / 2;
					ctx.font = fontSize + "em CenturyStd-Book";
					ctx.textBaseline = "top";
					var text = fields.totalTextSuperScript.value
					let textX = Math.round(width / 2 + ctx.measureText(label).width) + 1
					let textY = height / 2 - width / 2 + width * 3 / 5;
					ctx.fillStyle = "#008578";
					ctx.fillText(text, textX, textY);
					ctx.save();
				}
			}
			pluginsArray.push(totalTextSuperscriptPlugin);
		}

		var myChart = new Chart(document.getElementById(uid), {
			type: 'doughnut',
			data: this.state.data,
			options: this.state.options,
			plugins: pluginsArray
		});
	}
	calcCf(len) {
		if (len == 1) return 1.6;
		if (len == 2) return 1.4;
		if (len == 3) return 1.3;
		if (len == 4) return 1.2;
		if (len == 5) return 1;
		return 1;
	}
	render() {
		if (!this.props.fields || !this.props.params) {
			return null;
		}
		const uid = this.props.rendering.uid.replace(/\D+/g, '');
		const fields = this.props.fields ? this.props.fields : null;
		const params = this.props.params ? this.props.params : null;
		const includeSc1 = params && params.IncludeSc1 ? params.IncludeSc1 : null;
		const includeSc2 = params && params.IncludeSc2 ? params.IncludeSc2 : null;
		const includeSc3 = params && params.IncludeSc3 ? params.IncludeSc3 : null;
		const includeSc4 = params && params.IncludeSc4 ? params.IncludeSc4 : null;
		const includeSc5 = params && params.IncludeSc5 ? params.IncludeSc5 : null;
		const includeSc6 = params && params.IncludeSc6 ? params.IncludeSc6 : null;
		const includeSc7 = params && params.IncludeSc7 ? params.IncludeSc7 : null;
		const includeSc8 = params && params.IncludeSc8 ? params.IncludeSc8 : null;
		const titleIncludeSc = params && params.titleIncludeSc ? params.titleIncludeSc : null;
		const totalTextIncludeSc = params && params.totalTextIncludeSc ? params.totalTextIncludeSc : null;

		const titleColor = params ? params.titleColor : null;

		const isExpEditor = this.props.context.pageEditing;

		// chart colors
		let colors = [
			"#5ac2e7", "#014f59", "#6a737c", "#010101",
			"#46d7ac", "#018578", "#5ac2e7", "#9c24b7"
		];

		// get field data as hash table
		const fieldData = function () {
			var data = {};
			function fillData(legendValue, legendLabel, color, includeSc, sc, idx) {
				var isAdd = isExpEditor ? legendValue && legendLabel : legendValue && legendValue.value && legendLabel && legendLabel.value;
				if (isAdd) {
					data[idx] = {
						value: legendValue ? legendValue : null,
						label: legendLabel ? legendLabel : null,
						color: color,
						includeSc: includeSc ? includeSc : null,
						sc: sc ? sc : null
					}
				}
			}
			fillData(fields.legendValue1, fields.legendLabel1, colors[0], includeSc1, fields.legendSuperScript1, 0);
			fillData(fields.legendValue2, fields.legendLabel2, colors[1], includeSc2, fields.legendSuperScript2, 1);
			fillData(fields.legendValue3, fields.legendLabel3, colors[2], includeSc3, fields.legendSuperScript3, 2);
			fillData(fields.legendValue4, fields.legendLabel4, colors[3], includeSc4, fields.legendSuperScript4, 3);
			fillData(fields.legendValue5, fields.legendLabel5, colors[4], includeSc5, fields.legendSuperScript5, 4);
			fillData(fields.legendValue6, fields.legendLabel6, colors[5], includeSc6, fields.legendSuperScript6, 5);
			fillData(fields.legendValue7, fields.legendLabel7, colors[6], includeSc7, fields.legendSuperScript7, 6);
			fillData(fields.legendValue8, fields.legendLabel8, colors[7], includeSc8, fields.legendSuperScript8, 7);
			return data;
		}
		// get data
		const chartFields = fieldData();

		// get values
		let dataPoints = function () {
			var arr = [];
			Object.keys(chartFields).map(function (item) {
				arr.push(chartFields[item].value.value);
			});
			return arr;
		};

		// get labels for chart
		let getLabels = function () {
			var arr = [];
			Object.keys(chartFields).map(function (item) {
				arr.push(chartFields[item].label.value);
			});
			return arr;
		};

		// get colors for chart
		let getColors = function () {
			var arr = [];
			Object.keys(chartFields).map(function (item) {
				arr.push(chartFields[item].color);
			});
			return arr;
		};

		const getValueByPrecision = (value) => {
			let res = value.value;
			const precision = params && params.precision ? params.precision : null;

			//To Fixed added to one and two decimal displays so that even if .0 or .00 is always shown
			if (precision) {
				switch (precision) {
					case "one-decimal":
						res = (Math.round(value.value * 10) / 10).toFixed(1);
						break;
					case "two-decimals":
						res = (Math.round(value.value * 100) / 100).toFixed(2);
						break;
					case "whole-number":
						res = Math.round(value.value * 10) / 10;//Avoid whole numbers
						break;
				}
			}
			else {
				res = Math.round(value.value * 100) / 100;
			}

			if (value.value)
				value.value = res;

			if (isNaN(value.value))
				value.value = 0;
			// console.log(`Value ${value.value}`)
			return value;
		};

		const calculateTotal = function () {
			let res = 0
			Object.keys(chartFields).map(function (item) {
				if (chartFields[item].value && chartFields[item].value.value &&
					!isNaN(parseFloat(chartFields[item].value.value)) && isFinite(chartFields[item].value.value))
					res += parseFloat(chartFields[item].value.value);
			});
			return getValueByPrecision({ value: res }).value;
		};

		const calculateInside = function () {
			const totalLabel = fields.totalLabel ? fields.totalLabel.value : "";
			const unit = params && params.numberUnit ? params.numberUnit : "";
			if (totalLabel && totalLabel.length > 0 && unit && unit.length > 0) {

				//Add a space between the unit and total label
				return `${unit.trim()} ${totalLabel.trim()}`.toUpperCase().split("").join(String.fromCharCode(8202))
			} else if (unit && unit.length > 0) {
				//only return unit if total Label is empty
				return unit.trim().toUpperCase().split("").join(String.fromCharCode(8202))
			} else {
				return ""
			}
		}
		// build chart data structure / fill chart with data
		this.state.data = {
			labels: getLabels(),
			datasets: [{
				data: dataPoints(),
				backgroundColor: getColors(),
				hoverBackgroundColor: getColors()
			}]
		};

		// chart options
		this.state.options = {
			legend: {
				display: false	// hide legend
			},
			cutoutPercentage: 70, // cut out circle / make it thinner
			maintainAspectRatio: true, //isExpEditor ? true : false
			responsive: true
		};

		this.state.calculateTotal = calculateTotal();
		this.state.label = calculateInside();
		// define superscript
		const superScript = (isSc, sc) => {
			return isSc === "include-super-script" ?
				<span className="superscript">
					<Text field={sc}></Text>
				</span> : null
		};

		const editTotalLabel = (totalLabel) => {
			return totalLabel ?
				<Text field={totalLabel}></Text> : null
		};

		function buildLegendLink(item) {
			const number = params && params.numberTypes ? params.numberTypes : null;

			let prefix = "", postfix = "";
			const unit = params && params.numberUnit ? params.numberUnit.substring(0, 1).toUpperCase() : null;
			if (number) {
				switch (number) {
					case "currency":
						prefix = "$";
						postfix = unit;
						break;
					case "number":
						prefix = "";
						postfix = "";
						break;
					case "percent":
						prefix = "";
						postfix = "%";
						break;
				}
			}

			return (
				<div key={item} className="legend-item">
					<div className="color-item" style={{ backgroundColor: `${chartFields[item].color}` }}></div>
					<div className="legend-value body-copy">
						<Text field={chartFields[item].label}></Text>, {prefix}<Text field={getValueByPrecision(chartFields[item].value)}></Text>{postfix}
						{superScript(chartFields[item].includeSc, chartFields[item].sc)}
					</div>
				</div>
			)
		};

		function buildLegend() {
			return (
				<div className="legend col-12 row">
					<div className="col-lg-6 col-sm-6 col-12">
						{Object.keys(chartFields).map(function (item, i) { return i < 4 ? buildLegendLink(item) : null })}
					</div>
					<div className="col-lg-6 col-sm-6 col-12">
						{Object.keys(chartFields).map(function (item, i) { return i >= 4 ? buildLegendLink(item) : null })}
					</div>
				</div>
			)
		};

		function buildParagraph() {
			return (
				<p>Edit chart label/text superscript: </p>
			)
		}
		return (
			<div className="donut-chart row">
				<div className="chart col-lg-4 col-sm-12">
					{isExpEditor ? buildParagraph() : null}
					{isExpEditor ? editTotalLabel(fields.totalLabel) : null}
					{isExpEditor ? superScript(totalTextIncludeSc, fields.totalTextSuperScript) : null}
					<canvas
						id={uid}
						className="donut-chart-canvas"
						width="300"
						height="300"
					/>
				</div>
				<div className="info col-lg-8 col-sm-12">
					<h2 style={{ color: `#${titleColor}` }}>
						<Text className="title" field={fields.title} />
						{superScript(titleIncludeSc, fields.titleSuperScript)}
					</h2>
					<BasicsRichText fields={fields} params={params} />
					{buildLegend()}
				</div>
			</div>
		);
	}
}


export default DonutChart;