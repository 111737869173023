import React from 'react';
import {Link} from 'react-router-dom'
import truncateString from '../../util/truncateString'

//change this number for title truncation amount changes
const numberOfCharsToTrimAfter = 30

const SearchResultItem = (props) => {
  const titleExists = props.searchResult.Title != "" 
  let title = titleExists ? props.searchResult.Title.trim() : ""
  title = truncateString(title, numberOfCharsToTrimAfter)
  return (
    <div className={`${props.visibilityClass} ppm-search-result-link-container ${titleExists ? "" : "noTitleHoverOnTypeThen"}`}>
      <Link className="ppm-search-result-container-link" 
          to={`${props.searchResult.Url}`}>
        <div className="ppm-search-result-container">
          {/* Add a seperate class if the title doesn't exist to change the grid template props */}
          <div 
            className={`${titleExists ? 
            "" : 
            "ppm-search-title-is-empty"} ppm-search-result-title-and-type`}>
            {titleExists ?
            <h3 className="ppm-search-result-title">{title}</h3>
            : null}
            <h4 className="ppm-search-result-type">{props.searchResult.Type}</h4>
          </div>
          <p className="ppm-search-result-blurb">{props.searchResult.Preview}...</p>
        </div>
      </Link>
    </div>
  )
}

export default SearchResultItem;
