import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './statisticsUi.css'
import StatisticsItem from '../StatisticsItem'

const TextToDisplayWhenUserAddsMoreThanFourStatsItems = `Each Row Will Only Display 4 Items`

const StatisticsUi = (props) => {
  if(!props.fields){
    return null
  } 
  const color = props.params && props.params.textColor ? `#${props.params.textColor}` : "" 
  const style = {
    color
  };
  let returnAppropriateClass = (numberOfStatItems) => {
    switch (numberOfStatItems) {
      case 1:
        return "ppm-row-margin-for-one-stat"
      case 2:
        return "ppm-row-margin-for-two-stats"
      case 3:
        return "ppm-row-margin-for-three-stats"
      case 4:
        return "ppm-row-margin-for-four-stats"
      default:
        return "ppm-row-margin-for-four-stats"
    } 
  }
  
  //set up var for titles
  const groupTitleOne = props.fields.statGroupOneTitle ? props.fields.statGroupOneTitle.value : ""
  const groupTitleTwo = props.fields.statGroupTwoTitle ? props.fields.statGroupTwoTitle.value : ""
  //set up bool checks for if titles exist
  const groupTitleOneExists = groupTitleOne !== ""
  const groupTitleTwoExists = groupTitleTwo !== ""
  
  //set up styles for containers based on if they have titles or not
  const groupOneCssClasses = groupTitleOneExists ? 
  "stats-ui-group-stats-container stats-ui-group-stats-container-with-title " :
    "stats-ui-group-stats-container stats-ui-group-stats-container-no-group-title "
  const groupTwoCssClasses = groupTitleTwoExists ? 
  "stats-ui-group-stats-container stats-ui-group-stats-container-with-title" :
    "stats-ui-group-stats-container stats-ui-group-stats-container-no-group-title "
  
    //set up style for outside container based on if inner content has title
  const groupOneContainerCssStyles = groupTitleOneExists ? "stats-ui-group-stats-title-container" :""
  const groupTwoContainerCssStyles = groupTitleTwoExists ? "stats-ui-group-stats-title-container" : ""

  //set up title classes 
  const groupTitleCss = "stats-ui-group-title "
  
  //set up vars containing number of stat items in each section
  const numberOfStatItemsInTopRow = props.rendering.placeholders["ppm-stats-ui-top"] ? props.rendering.placeholders["ppm-stats-ui-top"].length : 0
  const numberOfStatItemsInBottomRow = props.rendering.placeholders["ppm-stats-ui-bottom"] ? props.rendering.placeholders["ppm-stats-ui-bottom"].length : 0

  //set up vars to hold placeholder data
  const topRow = props.rendering.placeholders["ppm-stats-ui-top"]
  const bottomRow = props.rendering.placeholders["ppm-stats-ui-bottom"]
  
  //apply the correct css style based on number of stat items
  const topRowClassForExtraMargin = returnAppropriateClass(numberOfStatItemsInTopRow)
  const bottomRowClassForExtraMargin = returnAppropriateClass(numberOfStatItemsInBottomRow)
  
  //page editing
  const {pageEditing} = props.context
  
  //render the component
  return (
    <div className="stats-ui-container "//row "  
      style={style}>
      {/* Only render the top section if it has content */}
      {numberOfStatItemsInTopRow > 0 ? 
      <div className={`stats-ui-group-container ${groupOneContainerCssStyles}`}>
        {groupTitleOne ? 
        <div className={`${groupTitleCss}`}>{groupTitleOne} 
        </div>: null}
        <div className={`${groupOneCssClasses} ${topRowClassForExtraMargin}`}>
          {props.context.pageEditing ? 
            <Placeholder name="ppm-stats-ui-top" {...props} />
            :
              topRow.map((item, index) => {
              return index < 4 ?
              <StatisticsItem key={`${index}top`} {...item} /> : null
              }) 
            }
        </div>
      </div> : null }
      {/* Only render the divider if both the top and bottom section have content */}
      {numberOfStatItemsInBottomRow > 0 &&
        numberOfStatItemsInTopRow > 0 ? 
        <div className="statistics-ui-divider"></div> 
        : null
      }
      {/* only render the bottom section if there is at least one stat item */}
      {numberOfStatItemsInBottomRow > 0 ? 
      <div className={`stats-ui-group-container ${groupTwoContainerCssStyles}`}>
        {groupTitleTwo ? 
        <div className={`${groupTitleCss}`}>{groupTitleTwo}
        </div> : null}
        <div className={`${groupTwoCssClasses} ${bottomRowClassForExtraMargin}`}>
          {props.context.pageEditing ? 
             <Placeholder name="ppm-stats-ui-bottom" {...props} />
            :
              bottomRow.map((item, index) => {
              return index < 4 ?
              <StatisticsItem key={`${index}bottom`} {...item} /> : null
              }) 
            }
        </div>
      </div>  : null
    }
    {/* Give feedback in experience editor if user applys more than 4 stat items in each row */}
    {pageEditing && (numberOfStatItemsInBottomRow > 4 || numberOfStatItemsInTopRow > 4) ?
    <h4>{TextToDisplayWhenUserAddsMoreThanFourStatsItems}</h4> : null} 
  </div>
  )
}

export default StatisticsUi;
