import React from 'react';
import axios from 'axios'
import {Image} from '@sitecore-jss/sitecore-jss-react';

// import canUseDom from '../../util/canUseDom'

//style for grid
import './dynamicContentGrid.css'

//grid card component and style
import '../GridCard/gridCard.css'
import GridCard from '../GridCard'

//graph ql 
import config from '../../temp/config';
import generateQuery from './contentQuery'
import generateJssQuery from './contentQueryForJss'

//mock response for code first
//simulate news as the data source
// import mockedResponse from './jssCodeFirstMockQueryResponse_News'
//simulate insight as the data source
import mockedResponse from './jssCodeFirstMockQueryResponse'

//use to bind scroll event
import {canUseDom} from '../../util/canUseDom'

const gqlHost = config.graphQLEndpointPath
const apiKey = config.sitecoreApiKey

//lazy loading var
const numberOfCardsToShowInitially = 15
const moreCardsToShowOnBottomScroll = 15
const bufferForOnScrollEvent = 350

class DynamicContentGrid extends React.Component {
  constructor(props){
    super(props)
    this.state  = {
      cardDataToRender: null,
      visibleCards: numberOfCardsToShowInitially,
      spinnerIsVisible: true
    }

    //set up refs to use for scroll calculation
    this.spinnerElement = React.createRef();
    this.dynamicContentGridContainer = React.createRef();

    //set up a placeholder for the timeout function
    this.onScrollTimeout = () => {}

    //bind on scroll event to increment number of visible cards
    if(canUseDom){
      window.addEventListener('scroll', this.changeNumberOfCardsToSee.bind(this));
    }
  }
  changeNumberOfCardsToSee = () => {
    //only execute the scroll handler if the content grid container is still mounted to the DOM
    if(this.dynamicContentGridContainer.current && this.spinnerElement.current){

      //calculate distance from spinner and distance user has scrolled
      const distanceY = window.pageYOffset + this.dynamicContentGridContainer.current.offsetTop
      let distanceFromSpinner = this.spinnerElement.current.offsetTop
  
      //if user has scrolled close enough to the bottom of the page then show more cards
      if(distanceY > distanceFromSpinner - bufferForOnScrollEvent){
        //set timeout to display spinner or something
        this.onScrollTimeout = setTimeout(() => this.incrementVisibleCards(this), 350)
      }
    }
  }
  incrementVisibleCards = (_this) => {
    //set up vars to use for logic
    const distanceY = window.pageYOffset + _this.dynamicContentGridContainer.current.offsetTop
    let distanceFromSpinner = _this.spinnerElement.current.offsetTop

    //check if it's still okay to increment the number of visible cards
    const okayToIncrement = _this.state.cardDataToRender && 
    _this.state.cardDataToRender.length > _this.state.visibleCards

    //,if not hide the spinner
    if(!okayToIncrement){
      _this.setState({
        spinnerIsVisible: false
      })
    }

    //increase the number of visible cards
    if(okayToIncrement && distanceY > (distanceFromSpinner - bufferForOnScrollEvent)){
      _this.setState({
        visibleCards: _this.state.visibleCards + moreCardsToShowOnBottomScroll
        })
    }
  }
  //set up var to container whether we are in jss code first mode
  jssCodeFirstState = this.props.routeData.databaseName === "available-in-connected-mode"
  styleForOtherStates = {
    display: "grid",
    justifyContent: "center",
    padding: "30px"
  }
  componentDidMount(){
    //get this from fields internal link
    let defaultSource = "/sitecore/content/ppmamerica/home/"
    let gridContentSource = this.props.params &&
    this.props.params.contentSource ? this.props.params.contentSource : defaultSource

    //set up graph ql query placeholder
    let graphQlQuery
    
    //check if running in jss start
    if(this.jssCodeFirstState){
      this.setState({
        cardDataToRender: mockedResponse.data.item.children
      })
    }else if(this.props.context.pageEditing){
      //generate jss query if in editing mode
      graphQlQuery = generateJssQuery(gridContentSource)
    }else{
      graphQlQuery = generateQuery(gridContentSource)
    }
    if(!this.jssCodeFirstState){
      axios({
        url: `${gqlHost}?sc_apikey=${apiKey}`,
        method: "post",
        data: {query: graphQlQuery}
      })
      .then((response) => {
          let cardData = response.data
          this.setState({
            cardDataToRender: cardData.data.item.children
          })
      })
      .catch(err => {
          console.log(`
          GraphQL Error Getting Navigation Data: 
          ${err}`)
      })
    }
  }
  componentWillUnmount(){
    //unbind the onscroll event handler
    window.removeEventListener('scroll', this.changeNumberOfCardsToSee);
    //clear the timeout function in case any execution is still pending during time of click to other page
    clearTimeout(this.onScrollTimeout)
  }
  render(){
    const {cardDataToRender} = this.state
    const {fields, context} = this.props
    //handle if no fields are available
    if(!fields || !cardDataToRender){
      return false
    }
    //display if there is no data available to display
    if(!cardDataToRender){
      return (
        <div style={this.styleForOtherStates}>
          <h2>
              The Source Location Doesn't Contain Needed Data
          </h2>
        </div>
      )
    }else if(cardDataToRender.length === 0){
      return (
      <div style={this.styleForOtherStates}>
        <h2>
            No Pages with Displayable Cards Were Found
        </h2>
      </div>
      )
    }
    const playIcon = fields.playIcon
    const defaultCardImage = fields.defaultCardImage
    const spinnerLazyLoading = fields.spinnerLazyLoading
    const cardData = () => {
      return <React.Fragment>
        {cardDataToRender.map((item, index) => {
          let indexToUseForReverseOrder = cardDataToRender.length - 1 - index
          return (
            <GridCard 
            visibilityClass={(index + 1) > this.state.visibleCards ? "hideCardFromDom": ""}
            key={`${indexToUseForReverseOrder}`}
            jssCodeFirstState={this.jssCodeFirstState}
            cardData={cardDataToRender[indexToUseForReverseOrder]}
            playIcon={playIcon}
            defaultCardImage={defaultCardImage}
            pageEditing={context.pageEditing}
            />
            )
          }, this)
        }
      </React.Fragment>
    }

    return (
      <React.Fragment>
        <div 
          ref={this.dynamicContentGridContainer}
          className={context.pageEditing ? "" : "ppm-dynamic-content-grid-container"}>
          {cardDataToRender ? cardData() : null}
        </div>
        {cardDataToRender.length > numberOfCardsToShowInitially ?
        <div 
          ref={this.spinnerElement} 
          style={{display: "grid", justifyContent: "center"}}
          >
          <Image 
            field={spinnerLazyLoading} 
            style={this.state.spinnerIsVisible ? 
            {} 
            : {display: "none"}}
            >
            </Image>
        </div>
        :null }
        {/* Use this if we want to display something to indicate that all content has been loaded
        will show when spinner is hidden because all content is visible */}
        {/* {!this.state.spinnerIsVisible ? (
          <div 
            style={{display: "grid", justifyContent: "center"}}
            >
            <h3 style={{padding: "2em"}}>All Content Has Been Loaded</h3>
          </div>
        ) : null } */}
        {/* TODO Remove after underline style is in place and set - used to create exact 20px space */}
          {/* <div style={{top:"75.5px", width:"100px", height:"40px",position:"absolute", backgroundColor: "green"}}></div>
          <div style={{top:"116.5px", width:"100px", height:"40px",position:"absolute", backgroundColor: "green"}}></div> */}
      </React.Fragment>
    )
  };
  }

export default DynamicContentGrid;
