const data = {
    buttons: {
        primary: {
            normal: {
                text: 'PRIMARY',
                className: 'button primary--normal'
            },
            hover: {
                text: 'HOVER',
                className: 'button primary--hover'
            },
            selected: {
                text: 'SELECTED',
                className: 'button primary--selected'
            },
            disabled: {
                text: 'DISABLED',
                className: 'button primary--disabled'
            }   
        },
        secondary: {
            normal: {
                text: 'SECONDARY',
                className: 'button secondary--normal'
            },
            hover: {
                text: 'HOVER',
                className: 'button secondary--hover'
            },
            selected: {
                text: 'SELECTED',
                className: 'button secondary--selected'
            },
            disabled: {
                text: 'DISABLED',
                className: 'button secondary--disabled'
            }   
        },
        tertiary: {
            normal: {
                text: 'TERTIARY',
                className: 'button tertiary--normal'
            },
            hover: {
                text: 'HOVER',
                className: 'button tertiary--hover'
            },
            selected: {
                text: 'SELECTED',
                className: 'button tertiary--selected'
            },
            disabled: {
                text: 'DISABLED',
                className: 'button tertiary--disabled'
            }   
        }
    },
    labels: [
        {
            text: 'Button label',
            className: 'label label--normal'
        },
        {
            text: 'Label hover',
            className: 'label label--hover'
        },
        {
            text: 'Label selected',
            className: 'label label--selected'
        },
        {
            text: 'Label disabled',
            className: 'label label--disabled'
        }
    ]
}
export default data;