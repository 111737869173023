import React from 'react';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import axios from 'axios';
import './videoPlayerUI.css';

class VideoPlayerUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoHtml: null
    };
  }
  
  componentDidMount() {
    if (! (this.props.fields.videoLink && this.props.fields.videoLink.value.href.trim().length > 0)) {
      return;
    }
    axios.get(`https://vimeo.com/api/oembed.json?url=${this.props.fields.videoLink.value.href}`)
    .then(res => {
      this.setState({videoHtml: res.data.html});
    })
    .catch(err => {
      console.error(err);
    })
  }
  render () {
    if (!this.props.fields) {
      return null;
    }
    const {pageEditing} = this.props.context
    const { videoHtml } = this.state;
    const richTextObj = {'value': videoHtml};
    const videoLink = this.props.fields.videoLink;
    videoLink.value.text = 'edit video link';
    const videoEditLink = this.props.context.pageEditing ? (
      <Link
        field={videoLink}
        className="vide-player__video-link"
      />
    ) : null;
    return (
      <React.Fragment>
        <div>
          {videoEditLink}
        </div>
        <div className="video-player__iframe-container">
          {/* Use this section to create custom display for experience
           editor with potential of listing possible video links */}
          {/* {pageEditing ? 
            null : 
            <RichText field={richTextObj}></RichText>
          } */}
          <RichText field={richTextObj}></RichText>
        </div>
      </React.Fragment>
    )
  }
}

export default VideoPlayerUI;
