const generateQuery = (path) => {
  return `
  {
    item(path: "${path}") {
        name
        url
        displayName
       template{
         name
       }
        ... on Contenttiledata {
          tileTitle {
            value
          }
          tileDescription {
            value
          }
          tileImage {
            src
               alt
          }
        }
      }
    }
`
}
export default generateQuery