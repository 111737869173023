import React from 'react';

const ScriptBody = props => {

	var createElementFromHTML = function(htmlString) {
	  var div = document.createElement('div');
	  div.innerHTML = htmlString.trim();

  	  var domEl = div.firstChild;
  	  if (domEl){
  	  	if (domEl.nodeName==="SCRIPT"){
  	  		var inner = domEl.innerHTML;
  	  		var attrs = Object.assign({}, domEl.attributes); 
  	  		var array = Object.keys(attrs).map(function(key) {
			    return {"name" : attrs[key].name , "value" : attrs[key].value };
			});
  	  		domEl = document.createElement("script");
  	  		array.map(function(item){
  	  			domEl.setAttribute(item.name, item.value);
  	  		});
  	  		domEl.onload = inner;
  		    domEl.onreadystatechange = inner;
  		    domEl.innerHTML = inner;
  	  	} //nodeName: "SCRIPT"
  	  }

	  return domEl;
	}
	
	var buildScript = function(src) {
	  if (typeof document !== 'undefined' && src && src.length > 0){
		var tag = createElementFromHTML(src);
		var el = document.getElementsByTagName('body');
		if (el.length > 0){
		  if(el[0].firstChild) el[0].insertBefore(tag, el[0].firstChild);
   		  else el[0].appendChild(tag);
		}
	  }
	}

	return (
		<>
			{props.fields.script && props.fields.script.value && props.fields.script.value.length > 0 ? buildScript(props.fields.script.value) : null}
		</>
	);
}

export default ScriptBody;
