import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import "./twoColumnRichText.css";
import superscriptStyle from "../../globalStylesCssInJs/superscriptStyle.js"

const defaultTitleColor = "000000"

const TwoColumnRichText = ({ fields, params }) => {
  // just in case if there is no datasource item passed to this component
  if (!fields) {
    return null;
  }

  const {
    title1,
    title2,
    textarea,
    superScriptTitle1,
    superScriptTitle2
  } = fields;

  const title1Color = params && params.title1Color ? params.title1Color : defaultTitleColor,
        title2Color = params && params.title2Color ? params.title2Color : defaultTitleColor,
        textareaColor = params && params.textareaColor ? params.textareaColor : defaultTitleColor,
        
        superScriptTitle1Option = params && params.includeSuperScriptTitle1 ? params.includeSuperScriptTitle1 : "no-super-script",
        includeSuperScriptTitle1 = superScriptTitle1Option === "include-super-script",

        superScriptTitle2Option = params && params.includeSuperScriptTitle2 ? params.includeSuperScriptTitle2 : "no-super-script",
        includeSuperScriptTitle2 = superScriptTitle2Option === "include-super-script"
  
  const title = title2 && title2.value ? (
    <h2 className="two-column-rich-text__title">
      <span
        style={{
          color: title1Color ? "#" + title1Color : ""
        }}
      >
        <Text field={title1} />
        {superScript(superScriptTitle1, includeSuperScriptTitle1)}
      </span>
      <br />
      <span
        style={{
          color: title2Color ? "#" + title2Color : ""
        }}
      >
        <Text field={title2} />
        {superScript(superScriptTitle2, includeSuperScriptTitle2)}
      </span>
    </h2>
  ) : (
    <h2 className="two-column-rich-text__title">
      <span
        style={{
          color: title1Color ? "#" + title1Color : ""
        }}
      >
        <Text field={title1} />
        {superScript(superScriptTitle1, includeSuperScriptTitle1)}
      </span>
    </h2>
  );

  return (
    <div
      className="two-column-rich-text__wrapper"
    >
      <div>
        <div className="row">
          <div className="col-lg-4 two-column-rich-text__title-container">
            {title}
          </div>

          <div className="col-lg-8">
            <RichText
              className="two-column-rich-text__textarea body-copy"
              field={textarea}
              style={{color: textareaColor ? `#${textareaColor}` : ""}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

//func for returning superscript jsx
const superScript = (superscriptField, showSuperScriptField) => {
  return showSuperScriptField ? 
    <span style={superscriptStyle}>
      <Text field={superscriptField}></Text>
    </span> : null
}

export default TwoColumnRichText;
