import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Helmet from 'react-helmet';

import Navigation from './components/Navigation/index.js';
import Footer from './components/Footer/index.js';

import sharedData from './util/sharedData'

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific. 
  
*/

const Layout = ({ sitecoreData }) => {
  const { route, context } = sitecoreData;
  const {pageEditing} = context
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Page'}
        </title>
        <meta name="title" content={(route.fields && route.fields.metaTitle && route.fields.metaTitle.value) || sharedData.metaTitle} />
        <meta name="description" content={(route.fields && route.fields.metaDescription && route.fields.metaDescription.value) || sharedData.metaDescription} />
      </Helmet>
      { !pageEditing ? <Placeholder name="ppm-head-script" rendering={route} routeData={route} context={context}/> : null }
      { !pageEditing ? <Placeholder name="ppm-nav"  rendering={route} routeData={route} context={context}/> : null }
      {/* root placeholder for the app, which we add components to using route data */}
      <main id="ppm-main-website-content-container" className={pageEditing ? '' : 'globalTrans'}>
        <Placeholder name="ppm-main" rendering={route} routeData={route} context={context}/>
      </main>
      { !pageEditing ? <Placeholder name="ppm-body-script" rendering={route} routeData={route} context={context}/> : null }
      { !pageEditing ? <Placeholder name="ppm-footer" rendering={route} routeData={route} context={context}/> : null }
    </React.Fragment>
  )
}

export default Layout;
