import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import './teamBioGrid.css';

const TeamBioGrid = (props) => {

  const uid = props ? "index-" + props.rendering.uid.replace(/\D+/g, '') : null;

  const {
    fields,
    params
  } = props;

  if (!fields) {
    return null;
  }
  const {
    title,
    descriptionTitle,
    descriptionBody
  } = fields;

  const {
    titleColor,
    descriptionTitleColor,
    descriptionBodyColor
  } = params
  return (
    <React.Fragment>
      <h3 id={uid}
        className="team-bio-grid__title"
        style={{color: titleColor ? `#${titleColor}` : '' }}
      >
        <Text field={title}/>
      </h3>

      <style dangerouslySetInnerHTML={{
          __html: [
             "#" + uid + '.team-bio-grid__title::after {',
             '  background-color: #' + titleColor,
             '}'
            ].join('\n')
          }}>
      </style>

      <div className="row team-bio-grid__description-wrapper">
        <div className="col-md-3">
          <h4 
            className="team-bio-grid__description-title"
            style={{color: descriptionTitleColor ? `#${descriptionTitleColor}` : '' }}>
            <Text field={descriptionTitle}/>
          </h4>
        </div>
        <div className="col-md-9">
          <RichText 
            field={descriptionBody}
            className="body-copy team-bio-grid__description-body"
            tag="p"
            style={{color: descriptionBodyColor ? `#${descriptionBodyColor}` : '' }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3"/>
        <div className="col-md-9">
          <div className="row">
            <Placeholder name="ppm-team-bio-card" {...props}/>
          </div>
        </div>
      </div>
    </React.Fragment> 
  )
}

export default TeamBioGrid;
