import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import './richTextSectionTItle.css';

// define superscript
const superScript = (sc) => {
  return sc && sc.value ? 
    <span className="rich-text-section-title__superscript">
      <Text field={sc}></Text>
    </span> : null;
};

const RichTextSectionTitle = ({fields, params}) => {
  if(!fields || !params){

    return null;
  }
  const {
    heading,
    titleSuperScript
  } = fields;

  const {
    titleColor
  } = params;

  var ttcolor = titleColor ? '#' + titleColor : '';
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="rich-text-section-title__title-container">
            <h2 className="rich-text-section-title__title_text" style={{ color: `${ttcolor}` }}>
              <Text field={heading} />
              {superScript(titleSuperScript)}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichTextSectionTitle;

