import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import RichTextSubcategoryListItem from './../RichTextSubcategoryListItem/index';
import RichTextSubcategoryListItemLink from './../RichTextSubcategoryListItemLink/index';
import './richTextSubcategoryListUi.css'
import BackgroundImage from '../BackgroundImage';
import Overlays from '../Overlays';
//use to bind scroll event
import {canUseDom} from '../../util/canUseDom'

//this component changes to a one column layout if below this pixel width
const threeColumnLayoutBreakPoint = 868; // actual window width will be 30px bigger due to paddings on the layout component

class RichTextSubcategoryListUi extends React.Component {
  constructor(){
    super()
    if (canUseDom) {
      this.state = {
        displayShouldBeOneColumn: false
      }
      this.componentContainer = React.createRef()
      this.changeColumnLayout = this.changeColumnLayout.bind(this);
      window.addEventListener('resize', this.changeColumnLayout);
    }else{
      this.state = {
        displayShouldBeOneColumn: false
      }
    }
  }
  changeColumnLayout () {
    const componentEl = this.componentContainer.current,
          style = componentEl.currentStyle || window.getComputedStyle(componentEl),
          margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
          componentWidth = componentEl.offsetWidth + margin,
          { displayShouldBeOneColumn } = this.state;

    if (componentWidth <= threeColumnLayoutBreakPoint && !displayShouldBeOneColumn) {
      this.setState({
        displayShouldBeOneColumn: true
      });
    } else if (componentWidth > threeColumnLayoutBreakPoint && displayShouldBeOneColumn) {
      this.setState({
        displayShouldBeOneColumn: false
      });
    }
  }

  //remove window event when component unmounts
  componentWillUnmount() {
    window.removeEventListener('resize', this.changeColumnLayout);
  }
  componentDidMount() {
    this.changeColumnLayout();
  }
  render(){
    if(!this.props.fields){
      return null
    }

    let {
      headingFirst, 
      headingSecond, 
      listItemsColTwo,
      listItemsColThree
    } = this.props.fields
    // PPM-466
    const colThreeHasList = listItemsColThree && listItemsColThree.length > 0,
          colTwoHasList = listItemsColTwo && listItemsColTwo.length > 0;

    let headingFirstColor = this.props.params && this.props.params.headingFirstColor ? this.props.params.headingFirstColor : "",
      headingSecondColor = this.props.params && this.props.params.headingSecondColor ? this.props.params.headingSecondColor : "",
      backgroundColor = this.props.params && this.props.params.backgroundColor? this.props.params.backgroundColor : ""
    // define back image
    const backImage = this.props.params && this.props.params.backgroundImage ? this.props.params.backgroundImage : null;
    // define focal point
    const focalPoint = this.props.params && this.props.params.focalPoint ? this.props.params.focalPoint : null;
    const firstHeadingStyle = {
      color: `#${headingFirstColor}`
    }
    const secondHeadingStyle = {
      color: `#${headingSecondColor}`
    }
    const backgroundColorStyle = {
      backgroundColor: `#${backgroundColor}`
    }
    if(this.state.displayShouldBeOneColumn){
      return (
        <div style={backgroundColorStyle} ref={this.componentContainer}>
          <BackgroundImage
            backgroundImage={backImage}
            focalPoint={focalPoint}
            routeData={this.props.routeData}>
            <Overlays
              overlayElement='rich-text-subcategory-list-ui mobile' 
              overlaySubElement='rich-text-subcategory-list-ui-sub'
              props={this.props}>
              <div 
                className="row rich-text-subcategory-list-ui__container"
                id="rich-text-subcategory-list-ui"
              >
                <div className="col-12">
                    <div className="rich-text-subcategory-list-ui__first-row-container-mobile">
                      <div className="rich-text-section-title__title-mobile" style={firstHeadingStyle}>
                        <Text field={headingFirst} />
                      </div>
                      <div className="rich-text-section-title__title-mobile" style={secondHeadingStyle}>
                        <Text field={headingSecond} />
                      </div>
                    </div>
                    <div className="rich-text-subcategory-list-ui__second-row-container-mobile">
                      <ul>
                        {listItemsColTwo && listItemsColTwo.map( (item, index) => {
                          return item.fields.targetLink ? 
                          (<li key={index} className="rich-text-subcategory-list-item-mobile">
                            <RichTextSubcategoryListItemLink index={index} item={item}/>
                          </li>
                          )
                          :
                          (<li key={index} className="rich-text-subcategory-list-item-mobile">
                            <RichTextSubcategoryListItem index={index} item={item}/>
                          </li>)
                        })}
                        {listItemsColThree && listItemsColThree.map( (item, index) => {
                          return item.fields.targetLink ? 
                          <li key={index} className="rich-text-subcategory-list-item-mobile">
                            <RichTextSubcategoryListItemLink index={index} item={item}/>
                          </li> :
                          <li key={index} className="rich-text-subcategory-list-item-mobile">
                            <RichTextSubcategoryListItem index={index} item={item}/>
                        </li>
                        })}
                      </ul>
                    </div>
                  </div>
              </div>
            </Overlays>
          </BackgroundImage>
        </div>
      )
    }else{
      const colThree = colThreeHasList ? (
        <div className={`${colTwoHasList ? 'col-4' : 'col-8'} rich-text-subcategory-list-right-column`}>
          <div className={`${!colTwoHasList ? 'pr-0' : ''} rich-text-subcategory-list-ui__third-row-container`}>
              <ul>
                {listItemsColThree && listItemsColThree.map( (item, index) => {
                  return item.fields.targetLink ? 
                  <li key={index} className="rich-text-subcategory-list-item">
                    <RichTextSubcategoryListItemLink index={index} item={item}/>
                  </li> :
                  <li key={index} className="rich-text-subcategory-list-item">
                    <RichTextSubcategoryListItem index={index} item={item}/>
                  </li>
                })}
              </ul>
          </div>
        </div>
      ) : null;
      const colTwo = colTwoHasList ? (
        <div className={colThreeHasList ? "col-4" : "col-8"}>
          <div className='rich-text-subcategory-list-ui__second-row-container'>
            <ul>
              {listItemsColTwo && listItemsColTwo.map( (item, index) => {
                return item.fields.targetLink ? 
                <li key={index} className="rich-text-subcategory-list-item">
                  <RichTextSubcategoryListItemLink index={index} item={item}/>
                </li> :
                <li key={index} className="rich-text-subcategory-list-item">
                  <RichTextSubcategoryListItem index={index} item={item}/>
                </li>
              })}
            </ul>
          </div>
        </div>
      ) : null;
      return (
        <div style={backgroundColorStyle} ref={this.componentContainer}>
         <BackgroundImage
            backgroundImage={backImage}
            focalPoint={focalPoint}
            routeData={this.props.routeData}>
            <Overlays
              props={this.props}
              overlayElement='rich-text-subcategory-list-ui' 
              overlaySubElement='rich-text-subcategory-list-ui-sub'
              >
                <div 
                  className="row rich-text-subcategory-list-ui__container"
                  id="rich-text-subcategory-list-ui"
                >
                  <div className="col-4">
                    <div className="rich-text-subcategory-list-ui__first-row-container">
                      <div className="rich-text-section-title__title" style={firstHeadingStyle}>
                        <Text field={headingFirst} />
                      </div>
                      <div className="rich-text-section-title__title" style={secondHeadingStyle}>
                        <Text field={headingSecond} />
                      </div>
                    </div>
                  </div>
                  {colTwo}
                  {colThree}
                </div>
            </Overlays>
          </BackgroundImage>
        </div>
      )
    }
  }
}

export default RichTextSubcategoryListUi;


