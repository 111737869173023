import React, {Component} from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import generateQuery from './contentQuery'
import generateJssQuery from './contentQueryForJss'

//jss code first mock query
import mockedResponse from './jssCodeFirstMockQueryResponse'

//bring in the dynamic grid card but only render one based on the data returned by the query
import GridCard from '../GridCard'

//query related
import axios from 'axios'
import config from '../../temp/config';
const gqlHost = config.graphQLEndpointPath
const apiKey = config.sitecoreApiKey

class InsightsGridPromoCard extends Component {
  constructor(props){
    super(props)
    this.state  = {
      cardDataToRender: null,
      apiCallMade: false
    }
  }
  jssCodeFirstState = this.props.routeData.databaseName === "available-in-connected-mode"
  componentDidMount(){
    //handle data fetching for the individual card
    let queryPath = this.props.params && 
      this.props.params.contentSource ? 
      this.props.params.contentSource : 
      "/sitecore/content/ppmamerica/home"
    let graphQlQuery
    if(this.jssCodeFirstState){
      this.setState({
        cardDataToRender: mockedResponse.data.item,
        apiCallMade: true
      })
    }else if(this.props.context.pageEditing){
      //generate jss jquery if in editing mode
      graphQlQuery = generateJssQuery(queryPath)
    }else{
      graphQlQuery = generateQuery(queryPath)
    }
    if(!this.jssCodeFirstState){
      axios({
        url: `${gqlHost}?sc_apikey=${apiKey}`,
        method: "post",
        data: {query: graphQlQuery}
      })
      .then((response) => {
          let cardData = response.data
          this.setState({
            cardDataToRender: cardData.data.item,
            apiCallMade: true
          })
      })
      .catch(err => {
          console.log(`
          GraphQL Error Getting Navigation Data: 
          ${err}`)
      })
    }
    
  }
  render(){
    const {fields, context} = this.props
    const {cardDataToRender, apiCallMade} = this.state
    // only check if api call was made because content source may default to home and return null
    if(!fields || !apiCallMade || cardDataToRender === null){
      return false
    }
    const playIcon = fields.playIcon
    const defaultCardImage = fields.defaultCardImage
    return (
      <GridCard 
        jssCodeFirstState={this.jssCodeFirstState}
        cardData={cardDataToRender}
        playIcon={playIcon}
        defaultCardImage={defaultCardImage}
        pageEditing={context.pageEditing}
      />
    )
  }
}

export default InsightsGridPromoCard;
