import React from 'react';
import { Text, Image, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import './RichTextTitleTextCtaUi.css'
import returnPaddingMultiplierStylesObj from '../../util/returnPaddingMultiplerStylesObj.js'

//button options
// "primary"
// "secondary"
// "tertiary"

const buttonClasses ={
  primary: "ppm-primary-button",
  secondary: "ppm-secondary-button",
  tertiary: "ppm-tertiary-button"
}

const globalButtonStyles = {
  height: "55px",
  width: "300px",
  borderRadius: "2px",
  fontFamily: "AzoSans-Bold",
  padding: "10px 60px",
  cursor: "pointer",
  textTransform: "uppercase",
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "center",
  textAlign: "-webkit-center"
}

const RichTextTitleTextCtaUi = props => {
  const { fields, params, context, rendering } = props
  if (!fields || !context) {
    return null
  }

  let buttonClass
  const buttonOption = params && params.buttonOption ? params.buttonOption : "primary"
  switch(buttonOption){
    case "primary":
      buttonClass = buttonClasses[buttonOption]
      break
    case "secondary":
      buttonClass = buttonClasses[buttonOption]
      break
    case "tertiary":
      buttonClass = buttonClasses[buttonOption]
      break
    default:
      buttonClass = buttonClasses[buttonOption]
      break
  }
   

  const titleColor = params && params.titleColor ? params.titleColor : ""
  const {title, bodyCopy, targetLink} = fields
  
  //set up the style object
  const paddingStyle = returnPaddingMultiplierStylesObj(params, "cta")

  const uid = rendering ? "index-" + rendering.uid.replace(/\D+/g, '') : null;
    
  return (
<div style={paddingStyle}>
  <div className="row">
    <div className="col-12">
      <div id={uid} className="ppm-rich-text-cta-ui-title-container">
        <h4 className="ppm-rich-text-cta-ui-title" style={{ color: `#${titleColor}` }}>
          <Text field={title}/>
        </h4>
        <style dangerouslySetInnerHTML={{
            __html: [
                "#" + uid + '.ppm-rich-text-cta-ui-title-container::after {',
                '  background-color: #' + titleColor,
                '}'
              ].join('\n')
            }}>
        </style>
      </div>
    </div>  
  </div>
  <div className="row" >
    <div className="col-12" >
      <div className="ppm-rich-text-cta-ui-body__container">
          <RichText field={bodyCopy}
            className="ppm-rich-text-cta-ui-body" 
            style={{ color: `#${titleColor}` }}/>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="ppm-rich-text-cta-ui-button-link__container">
            {context.pageEditing ? "Click to edit URL " : ""}
            <Link className="ppm-rich-text-cta-ui-button-link" field={targetLink}>
              <button className={buttonClass} style={globalButtonStyles}>
                {targetLink.value.text }
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>
  )
}

export default RichTextTitleTextCtaUi;