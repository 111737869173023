const getSearchTermFromUrl = () => {
    if(window.location.search !== ""){
        let splitQueryParamsArray = window.location.search.split("searchTerm=")
        let removedLaterOccurringParams = splitQueryParamsArray[splitQueryParamsArray.length-1].split("&")[0]
        let arrayOfChars = removedLaterOccurringParams.split('')
        let encodeSpaces =  arrayOfChars.map(char => {
          if(char === '+'){
            return "%20"
          }else{
            return char
          }
        })
        let endResult = encodeSpaces.join('')
        return endResult
      }else{
        return ""
      }
}

export default getSearchTermFromUrl