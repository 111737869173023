const query = `
query Nav {
    navRoot: item(path: "/sitecore/content/ppmamerica/Content/main-nav") {
      ... on Navigation {
        children {
          ... on Navitem {
            targetName {
              value
            }
            targetLink {
              url
              text
            }
            visible {
              boolValue
            }
            children {
              ... on Navitem {
                targetName {
                  value
                }
                targetLink {
                  url
                  text
                }
                visible {
                  boolValue
                }
                children {
                  ... on Navitem {
                    targetName {
                      value
                    }
                    targetLink {
                      url
                      text
                    }
                    visible {
                      boolValue
                    }
                    children {
                      ... on Navitem {
                        targetName {
                          value
                        }
                        targetLink {
                          url
                          text
                        }
                        visible {
                          boolValue
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default query