const generateQuery = (path) => {
  return `
{
  item(path: "${path}") {
    children {
      name
      url
     template{
       name
     }
      ... on Contenttiledata {
        tileTitle {
          value
        }
        tileDescription {
          value
        }
        tileImage {
          src
             alt
        }
      }
    }
  }
  }
   `
}
export default generateQuery