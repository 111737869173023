import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './imagesGrid.css';

const ImagesGrid = (props) => {
  const {
    fields,
    params
  } = props;
  const {
    title
  } = fields;
  const numImages = props.rendering.placeholders['ppm-individual-image'].length;
  return (
    <div>
      <h3 className="images-grid__title">
        <Text field={title} />
      </h3>

      <div className="row images-grid__content">
        <Placeholder name="ppm-individual-image" {...props} numImages={numImages}/>
      </div>
    </div>
  )
};

export default ImagesGrid;
