import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const RichTextDisclaimerUi = ({fields}) => {
    if(!fields){
      return null
    }
    return (
      <div className="row">
      <div className="col-12">
        <div className="rich-text-disclaimer-ui__body-container">
          <div className="disclaimer">
            <RichText field={fields.bodyText} />
          </div>
        </div>
      </div>
    </div>
    )
}

export default RichTextDisclaimerUi;

