import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './basicsRichText.css';

const BasicsRichText = ({ fields, params }) => {
  if (!fields) return null;
  const color = params ? `#${params.textColor}` : null,
        fontStyle = params ? params.fontStyle : null;

  return (
    <RichText 
      tag="p" 
      style={{ color }}
      className={`basics-rich-text-textarea ${fontStyle}`}
      field={fields.textarea}
    />
  )
}

export default BasicsRichText;
