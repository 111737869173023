import React from 'react';
import { Link } from "@sitecore-jss/sitecore-jss-react";
import './SubNavigationLink.css';

class SubNavigationLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
      isActive: false
    };
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
    this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  onMouseEnterHandler(e) {
    this.setState({
      isHover: true
    });
  }

  onMouseLeaveHandler(e) {
    this.setState({
      isHover: false,
      isActive: false
    });
  }

  onMouseDownHandler(e) {
    this.setState({
      isActive: true
    });
  }

  onMouseUpHandler(e) {
    this.setState({
      isActive: false
    });
  }
  onFocusHandler(e) {
    this.setState({
      isActive: true
    });
  }
  onBlurHandler(e) {
    this.setState({
      isActive: false
    });
  }
  
  render() {
    const {
      fields,
      params,
      linkTextClass,
      arrowClass
    } = this.props;

    if(!fields || !fields.targetLink){
      return null;
    }
    const hoverClass = this.state.isHover ? 'hover' : '',
          activeClass = this.state.isActive ? 'active' : '';
    const linkText = linkTextClass == 'heading-2' ? (
      <h2 className="side-nav__link-wrapper">
        <Link 
          field={fields.targetLink}
          showLinkTextWithChildrenPresent={true}
          className={`side-nav__link ${hoverClass} ${activeClass} ${linkTextClass? linkTextClass : ''}`}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onMouseDown={this.onMouseDownHandler}
          onMouseUp={this.onMouseUpHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        />
      </h2>
    ) : (
        <Link 
          field={fields.targetLink}
          showLinkTextWithChildrenPresent={true}
          className={`side-nav__link subnav__link ${hoverClass} ${activeClass} ${linkTextClass? linkTextClass : ''}`}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onMouseDown={this.onMouseDownHandler}
          onMouseUp={this.onMouseUpHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        />
    );
    return (
      <div className="side-nav">
        {linkText}
        <a 
          href={fields.targetLink.value.href}
          className={`side-nav__right-arrow ${hoverClass} ${activeClass} ${arrowClass? arrowClass : ''}`}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
          onMouseDown={this.onMouseDownHandler}
          onMouseUp={this.onMouseUpHandler}
          onFocus={this.onFocusHandler}
          onBlur={this.onBlurHandler}
        >
          <RightArrow/> 
          {/* abc */}
        </a>
      </div>
    );
  }
}

const RightArrow = () => (
  <svg role="img" aria-hidden="true" focusable="false" viewBox="0 0 8 13">
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M6.447 5.668L1.94 1.161a.55.55 0 0 0-.779.779l4.12 4.12-4.121 4.152a.55.55 0 1 0 .781.775L6.45 6.446a.55.55 0 0 0-.002-.778z" fill="black"></path>
    </g>
  </svg>
)

export default SubNavigationLink;