import React from 'react';
import { Text, Placeholder, Link } from '@sitecore-jss/sitecore-jss-react';
import './insightsPromoGrid.css';
import InsightsGridPromoCard from '../InsightsGridPromoCard';


//If going to be used elsewhere, move to a util folder
const useSitecoreUrlSpaceToDashEncoding = (string) => {
  if(string){
    return string.replace(' ', '-')
  }
}

const GlobalInsightsPromoGrid = (props) => {
  if (!props.fields) {
    return null;
  }
  const uid = props ? "index-" + props.rendering.uid.replace(/\D+/g, '') : null;
  const { fields } = props;
  const pageEditing = props.context.pageEditing
  const titleColor = props.params &&
    props.params.titleColor ?
    props.params.titleColor : null
  const { title } = fields;

  const contentSourceOne = props && props.fields && props.fields.InsightsGridCardOne ? props.fields.InsightsGridCardOne.value : null;
  const contentSourceTwo = props && props.fields && props.fields.InsightsGridCardTwo ? props.fields.InsightsGridCardTwo.value : null;
  const contentSourceThree = props && props.fields && props.fields.InsightsGridCardThree ? props.fields.InsightsGridCardThree.value : null;

  const defaultDisplayFields = {
    playIcon: props && props.fields && props.fields.InsightsGridCardPlayIcon ? props.fields.InsightsGridCardPlayIcon : null,
    defaultCardImage: props && props.fields && props.fields.InsightsGridCardDefaultCardImage ? props.fields.InsightsGridCardDefaultCardImage : null,
  };

  // data source for card 1
  const paramsOne = {
    contentSource: contentSourceOne ? '/sitecore/content/ppmamerica/home' + useSitecoreUrlSpaceToDashEncoding(contentSourceOne.href) : null
  }

  // data source for card 2
  const paramsTwo = {
    contentSource: contentSourceTwo ? '/sitecore/content/ppmamerica/home' + useSitecoreUrlSpaceToDashEncoding(contentSourceTwo.href) : null
  }
  
  // data source for card 3
  const paramsThree = {
    contentSource: contentSourceThree ? '/sitecore/content/ppmamerica/home' + useSitecoreUrlSpaceToDashEncoding(contentSourceThree.href) : null
  }
  
  const cardOne = contentSourceOne ? (
    <InsightsGridPromoCard
      params={paramsOne}
      fields={defaultDisplayFields}
      routeData={props.routeData}
      context={props.context}>
    </InsightsGridPromoCard>
  ) : null;

  const cardTwo = contentSourceTwo ? (
    <InsightsGridPromoCard
      params={paramsTwo}
      fields={defaultDisplayFields}
      routeData={props.routeData}
      context={props.context}>
    </InsightsGridPromoCard>
  ) : null;

  const cardThree = contentSourceThree ? (
    <InsightsGridPromoCard
      params={paramsThree}
      fields={defaultDisplayFields}
      routeData={props.routeData}
      context={props.context}>
    </InsightsGridPromoCard>
  ) : null;

  return (
    <React.Fragment>
      <style dangerouslySetInnerHTML={{
        __html: [
          "#" + uid + '.insights-promo__title::after {',
          '  background-color: #' + titleColor,
          '}'
        ].join('\n')
      }}>
      </style>
      <h3
        id={uid}
        className="insights-promo__title"
        style={{ color: titleColor ? `#${titleColor}` : '' }}
      >
        <Text field={title} />
      </h3>
      <div className="row">
        <div className="col-12">
          <div className={pageEditing ? " " : "ppm-insights-promo-grid-container"}>
            {cardOne}
            {cardTwo}
            {cardThree}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GlobalInsightsPromoGrid;