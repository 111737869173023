const navData = {
  data: {
    navRoot: {
      children: [
        {
          targetName: { value: "Investment Solutions" },
          targetLink: {
            url: "/investment-solutions",
            text: "Investment Solutions"
          },
          visible: { boolValue: true },
          children: [
            {
              targetName: { value: "Collateralized Loan Obligations" },
              targetLink: {
                url: "/investment-solutions/collateralized-loan-obligations",
                text: "Collateralized Loan Obligations"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "Collective Investment Trusts" },
              targetLink: {
                url: "/investment-solutions/collective-investment-trusts",
                text: "Collective Investment Trusts"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "Mutual Funds" },
              targetLink: {
                url: "/investment-solutions/mutual-funds",
                text: "Mutual Funds"
              },
              visible: { boolValue: true },
              children: [
                {
                  targetName: { value: "PPM Funds Literature" },
                  targetLink: {
                    url:
                      "/investment-solutions/mutual-funds/ppm-funds-literature",
                    text: "PPM Funds Literature"
                  },
                  visible: { boolValue: true },
                  children: [
                    {
                      targetName: { value: "PKPIX" },
                      targetLink: {
                        url:
                          "/investment-solutions/mutual-funds/ppm-funds-literature/pkpix",
                        text: "PKPIX"
                      },
                      visible: { boolValue: true }
                    },
                    {
                      targetName: { value: "PKPIX2" },
                      targetLink: {
                        url:
                          "/investment-solutions/mutual-funds/ppm-funds-literature/pkpix2",
                        text: "PKPIX"
                      },
                      visible: { boolValue: true }
                    }
                  ]
                }
              ]
            },
            {
              targetName: { value: "Strategies" },
              targetLink: {
                url: "/investment-solutions/strategies",
                text: "Strategies"
              },
              visible: { boolValue: true },
              children: [
                {
                  targetName: { value: "Commercial Real Estate" },
                  targetLink: {
                    url:
                      "/investment-solutions/strategies/commercial-real-estate",
                    text: "Commercial Real Estate"
                  },
                  visible: { boolValue: true },
                  children: []
                },
                {
                  targetName: { value: "Equity" },
                  targetLink: {
                    url: "/investment-solutions/strategies/equity",
                    text: "Fixed Income"
                  },
                  visible: { boolValue: true },
                  children: [
                    {
                      targetName: { value: "Large Cap Core" },
                      targetLink: {
                        url:
                          "/investment-solutions/strategies/equity/large-cap-core",
                        text: "Large Cap Core"
                      },
                      visible: { boolValue: true }
                    }
                  ]
                },
                {
                  targetName: { value: "Fixed Income" },
                  targetLink: {
                    url: "/investment-solutions/strategies/fixed-income",
                    text: "Fixed Income"
                  },
                  visible: { boolValue: true },
                  children: [
                    {
                      targetName: { value: "Core Fixed Income" },
                      targetLink: {
                        url:
                          "/investment-solutions/strategies/fixed-income/core-fixed-income",
                        text: "Core Fixed Income"
                      },
                      visible: { boolValue: true }
                    }
                  ]
                },
                {
                  targetName: { value: "Private Equity" },
                  targetLink: {
                    url: "/investment-solutions/strategies/private-equity",
                    text: "Private Equity"
                  },
                  visible: { boolValue: true },
                  children: []
                }
              ]
            }
          ]
        },
        {
          targetName: { value: "Our Insights" },
          targetLink: { url: "/our-insights", text: "Our Insights" },
          visible: { boolValue: true },
          children: []
        },
        {
          targetName: { value: "Our Story" },
          targetLink: { url: "/our-story", text: "Our Story" },
          visible: { boolValue: true },
          children: [
            {
              targetName: { value: "Contact Us" },
              targetLink: { url: "/our-story/contact-us", text: "Contact Us" },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "ESG Investing" },
              targetLink: {
                url: "/our-story/esg-investing",
                text: "ESG Investing"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "In The News" },
              targetLink: {
                url: "/our-story/in-the-news",
                text: "In The News"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "Our Community" },
              targetLink: {
                url: "/our-story/our-community",
                text: "Our Community"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "Our Culture" },
              targetLink: {
                url: "/our-story/our-culture",
                text: "Our Culture"
              },
              visible: { boolValue: true },
              children: []
            },
            {
              targetName: { value: "Our Team" },
              targetLink: { url: "/our-story/our-team", text: "Our Team" },
              visible: { boolValue: true },
              children: []
            }
          ]
        }
      ]
    }
  }
};
export default navData