import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './insightsPromoGrid.css';

const InsightsPromoGrid = (props) => {
  if (!props.fields) {
    return null;
  }
  const uid = props ? "index-" + props.rendering.uid.replace(/\D+/g, '') : null;
  const {fields} = props;
  const pageEditing = props.context.pageEditing
  const titleColor = props.params && 
  props.params.titleColor ? 
  props.params.titleColor: null
  const { title } = fields;
  return (
    <React.Fragment>
       <style dangerouslySetInnerHTML={{
          __html: [
             "#" + uid + '.insights-promo__title::after {',
             '  background-color: #' + titleColor,
             '}'
            ].join('\n')
          }}>
      </style>
      <h3
        id={uid}
        className="insights-promo__title"
        style={{color: titleColor? `#${titleColor}` : ''}}
      >
        <Text field={title}/>
      </h3>
      <div className="row">
        <div className="col-12">
          <div className={pageEditing ? " " : "ppm-insights-promo-grid-container"}>
            <Placeholder name="ppm-insights-promo-card" {...props}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InsightsPromoGrid;