import React from 'react';
import {Image} from '@sitecore-jss/sitecore-jss-react';
//component for search result
import SearchResultItem from '../SearchResultItem/index'
//style
import './searchPageUi.css'
//util
import {canUseDom} from '../../util/canUseDom'
import getSearchTermFromUrl from '../../util/getSearchTermFromUrl'
import jssMockData from './jssMockData'


//lazy loading vars
const onFirstViewNumberOfResultsToShow = 15
const moreResultsToShowOnBottomScroll = 15
const bufferForOnScrollEvent = 550
const timeOutBetweenSeeingSpinnerAndGettingMoreContent = 350

class SearchPageUi extends React.Component {
  constructor(){
    super()
    this.state = {
      searchResults : null,
      searchTerm : canUseDom ? getSearchTermFromUrl() : "",
      origin: canUseDom ? window.location.origin : "",
      visibleResults: onFirstViewNumberOfResultsToShow,
      spinnerIsVisible: true
    }

    //set up refs to use for scroll calc
    this.spinnerElement = React.createRef()
    this.searchResultsUi = React.createRef()

    //timeout function
    this.onScrollTimeout = () => {}

    //bind on scroll event to increment number of search results
    if(canUseDom){
      window.addEventListener('scroll', this.changeNumberOfResultsToSee.bind(this));
    }
  }
  changeNumberOfResultsToSee = () => {
     //only execute the scroll handler if the search ui is still mounted to the DOM and the spinner is visible
     if(this.searchResultsUi.current && this.spinnerElement.current){

      //calculate distance from spinner and distance user has scrolled
      const distanceY = window.pageYOffset + this.searchResultsUi.current.offsetTop
      let distanceFromSpinner = this.spinnerElement.current.offsetTop
  
      //if user has scrolled close enough to the bottom of the page then show more cards
      if(distanceY > distanceFromSpinner - bufferForOnScrollEvent){
        //set timeout to display spinner or something
        this.onScrollTimeout = setTimeout(() => this.incrementVisibleResults(this), timeOutBetweenSeeingSpinnerAndGettingMoreContent)
      }
    }
  }
  incrementVisibleResults = (_this) => {
    //set up vars to use for logic
    const distanceY = window.pageYOffset + _this.searchResultsUi.current.offsetTop
    let distanceFromSpinner = _this.spinnerElement.current.offsetTop

    //check if it's still okay to increment the number of visible cards
    const okayToIncrement = _this.state.searchResults && 
    _this.state.searchResults.length > _this.state.visibleResults

    //,if not hide the spinner
    if(!okayToIncrement){
      _this.setState({
        spinnerIsVisible: false
      })
    }

    //increase the number of visible cards
    if(okayToIncrement && distanceY > (distanceFromSpinner - bufferForOnScrollEvent)){
      _this.setState({
        visibleResults: _this.state.visibleResults + moreResultsToShowOnBottomScroll
        })
    }
  }
  componentDidMount(){
    //only get the data if in client side
    if(canUseDom){
      if(this.props.routeData.databaseName === "available-in-connected-mode"){
        this.setState({searchResults: jssMockData})
      }else{
        let urlToFetchFrom = `${window.location.origin}/sitecore/api/ppmsearch/${this.state.searchTerm}`
        fetch(urlToFetchFrom)
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          this.updateStateWithSearchResultData(myJson)
        })
        .catch((error) => {
          console.log(error)
        })
      }
    }
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.changeNumberOfResultsToSee);
    clearTimeout(this.onScrollTimeout)
  }
  updateStateWithSearchResultData = (searchResults) => {
    this.setState({
      searchResults: searchResults
    })
  }
  styleForOtherStates = {
    display: "grid",
    justifyContent: "center",
    padding: "30px"
  }
  render(){
    const {spinnerLazyLoading} = this.props.fields
    if(!this.state.searchResults){
      return (
      <div className = "container" >
        <div className="row">
          <div className="col-12" style={this.styleForOtherStates}>
            <h2>
                Loading Search Results...
            </h2>
          </div>
        </div>
      </div >
      )
    }else if(this.state.searchResults.length === 0){
      return(
        <div className = "container" >
          <div className="row">
            <div className="col-12" style={this.styleForOtherStates}>
              <h2>
              No Search Results Were Found
              </h2>
            </div>
          </div>
        </div >
        )
    }
    else{
      const {searchResults, visibleResults} = this.state
      return (
  <div className="container">
    <div className="row">
      <div 
        ref={this.searchResultsUi} 
        className="col-12">
        <div className="ppm-search-results-list-container">
          <h2 className="ppm-search-results-list-title">Results</h2>
          {this.state.searchResults.map((searchResult, index) => (
            <SearchResultItem 
              key={index} 
              searchResult={searchResult} 
              visibilityClass={index + 1 < this.state.visibleResults ? "" : "hideResultFromDom" }/>
          ))}
        </div>
        {/* only show the spinner initially if the number of results that comes back is greater than 15 */}
        {searchResults.length > onFirstViewNumberOfResultsToShow ?
        <div 
          ref={this.spinnerElement} 
          style={{display: "grid", justifyContent: "center"}}
          >
          <Image 
            field={spinnerLazyLoading} 
            style={this.state.spinnerIsVisible ? 
            {} 
            : {display: "none"}}
            >
            </Image>
        </div>
        : null}
      </div>
    </div>
  </div>
      )
    }
    }
}

export default SearchPageUi;
