import React from "react";

const ScriptHead = (props) => {
  var createScriptElement = function(domEl) {
    if (domEl) {
      //nodeName: "SCRIPT"
      if (domEl.nodeName === "SCRIPT") {
        var inner = domEl.innerHTML;
        var attrs = Object.assign({}, domEl.attributes);
        var array = Object.keys(attrs).map(function(key) {
          return { name: attrs[key].name, value: attrs[key].value };
        });
        domEl = document.createElement("script");
        array.map(function(item) {
          domEl.setAttribute(item.name, item.value);
        });
        domEl.onload = inner;
        domEl.onreadystatechange = inner;
        domEl.innerHTML = inner;
        var el = document.getElementsByTagName("head");
        if (el.length > 0) {
          el[0].appendChild(domEl);
          //   console.log(`Constructed DOM ELEMENT ${domEl}`);
          return domEl;
        }
      }
    }
  };

  var localHostOverrideEmbedString = false;

  var createElementFromHTML = function(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    div.childNodes.forEach(createScriptElement);
  };

  var buildScript = function(src) {
    if (typeof document !== "undefined" && src && src.length > 0) {
      var tag = createElementFromHTML(
        localHostOverrideEmbedString ? mockString : src
      );
    }
  };

  const mockString = `<script src="https://someDomain.js"  type="text/javascript" charset="UTF-8" ></script>
  <script type="text/plain">
  function SomeWrapper() { 
    var test = "test";
  }
  SomeWrapper()
  </script>
  <script>(function(){console.log('SecondScriptLoaded')})()</script>`;

  return (
    <>
      {props.fields.script &&
      props.fields.script.value &&
      props.fields.script.value.length > 0
        ? buildScript(props.fields.script.value)
        : null}
    </>
  );
};

export default ScriptHead;
