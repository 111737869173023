import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import SubNavigationLink from './../SubNavigationLink/index';

import './SubNavigation.css';

const SubNavigation = (props) => {
 return <React.Fragment>
    <div className="side-nav">
      <div className="side-nav__title-container">
        <h3 className="side-nav__title">
          <Text field={props.fields.title}/>
        </h3>
      </div>
      <Placeholder name="ppm-subnavigation-link" {...props}/>
    </div>
  </React.Fragment>
};

export default SubNavigation;