import React from 'react';
import { Text, Image, Link, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './ticker.css';

const CURRENT_QUARTERLY_HOLDINGS = 'Quarterly Holdings (Most Recent):';
const PDF = 'pdf',
      EXCEL = 'excel';

function Ticker (props) {
    const {
        fields
    } = props;
    
    //TODO Move these into content types and dynamically render all of them so users can add to this group
    fields.summaryProspectusLink.value.text = 'Summary Prospectus:';
    fields.statutoryProspectus.value.text = 'Statutory Prospectus:';
    fields.statementOfAdditionalInformation.value.text = 'Statement of Additional Information:';
    fields.quarterlyHoldings.value.text = 'Quarterly Holdings (Historical):';
    fields.currentQuarterlyHoldings.value.text = CURRENT_QUARTERLY_HOLDINGS;
    fields.semiAnnualReport.value.text = 'Semi-Annual Report:';
    fields.institutionalAccountApplication.value.text = 'Institutional Account Application:';
    fields.annualReport.value.text = 'Annual Report:';
    fields.factCard.value.text = 'Fact Card:';

    function generateLink (field, iconType) {
        // if link is set, render link
        const isLinkPresent = field.value.href.trim().length > 0;

        // if pdf is present, use pdf. Else if excel is present use it. Else display N/A
        let icon = 'N/A'
        if (isLinkPresent) {
            if (iconType === EXCEL) {
                icon = (
                    <Link
                        field={field}
                        className="ticker__link-icon"
                    >
                        <img 
                            src={props.iconExcel.value.src}
                            className="ticker__icon">
                        </img>
                    </Link>
                )
            } else if (iconType === PDF){
                icon = (
                    <Link
                        field={field}
                        className="ticker__link-icon"
                    >
                        <img 
                            src={props.iconPdf.value.src}
                            className="ticker__icon">
                        </img>
                    </Link>
                )
            }
        }
        if (isLinkPresent || props.context.pageEditing) {
            return (
                <div className="ticker__item body-copy">
                    <Link 
                        field={field}
                        className="ticker__link-item"
                    />
                    {icon}
                </div>
            )
        }
        return null;
    }
    return (
        <div>
            <p className="ticker__title body-copy">Ticker: <Text field={fields.fundTicker}/></p>
            {generateLink(fields.summaryProspectusLink, PDF)}
            {generateLink(fields.statutoryProspectus, PDF)}
            {generateLink(fields.statementOfAdditionalInformation, PDF)}
            {generateLink(fields.currentQuarterlyHoldings, EXCEL)}
            {generateLink(fields.quarterlyHoldings, PDF)}
            {generateLink(fields.semiAnnualReport, PDF)}
            {generateLink(fields.annualReport, PDF)}
            {generateLink(fields.factCard, PDF)}
            {generateLink(fields.institutionalAccountApplication, PDF)}
        </div>
    )
}

export default Ticker;