import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const LinkedTitleRichText = (props) => {
  return (
    <div className="linked-title-rich-text">
      <Placeholder name="ppm-linked-title-richtext-link" {...props}/>
    </div>
  )
}

export default LinkedTitleRichText;
