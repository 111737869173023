import React from 'react';
import './Overlays.css';

const Overlays = (
	{props, 
	children, 
	overlayElement, 
	overlaySubElement, 
	overlay,
	leftOverlayColor, 
	rightOverlayColor, 
	overlayColor}) => {
	
	//check if overlay is passed
	const isOverlay = overlay && overlay!=="none";

	// overlay for left or right side
	const overlayClass = isOverlay ? overlayElement + " " + "overlay" + " " + overlay : overlayElement;

	// overlay for left/right side only
	const subOverlayClass = isOverlay && overlay=="left-right-side" ? 
								overlaySubElement + " " + "overlay" + " " + overlay : 
								overlaySubElement;
	const uid = props && props.rendering ? "index-" + props.rendering.uid.replace(/\D+/g, '') : null;

	// convert hex color number to rgba for CSS pseudo element
	const hexToRGBA = (hex, opacity) => {
		if (!hex)
			return 'rgba(rgba(255, 255, 255, ' + opacity + ')';
		else 
    		return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { 
    			return parseInt(hex.length%2 ? l+l : l, 16) 
    		}).concat(opacity||1).join(',') + ')';
	};
	// opacity for left/right angle
	const 	afterOpacity = 0.3,
			beforeOpacity = 0.58;
	const rightSideSelectors = ['.right-side::after' , '.right-side::before' , ' .left-right-side#sub-wrapper::after' , ' .left-right-side#sub-wrapper::before']
	const leftSideSelectors = ['.left-side::after' , '.left-side::before' , '.left-right-side::after' , '.left-right-side::before']
	const setPseudoColor = (pseudoElement, opacity) => {
		let overlayColorToApply 
		if(leftSideSelectors.indexOf(pseudoElement) > -1){
			overlayColorToApply = leftOverlayColor ? leftOverlayColor : overlayColor
		}
		else if (rightSideSelectors.indexOf(pseudoElement) > -1){
			overlayColorToApply = rightOverlayColor ? rightOverlayColor : overlayColor
		}else{
			overlayColorToApply = overlayColor
		}	
		let pseudo = ['#' + uid + "." + overlayElement + pseudoElement + ' {',
					 '  background-color: ' + hexToRGBA(overlayColorToApply, opacity),
					 '}'].join('\n');
		return pseudo;
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{
			  	__html: [

			  		setPseudoColor('.left-side::after', afterOpacity),
			  		setPseudoColor('.left-side::before', beforeOpacity),
			     
			     	setPseudoColor('.right-side::after', afterOpacity),
			     	setPseudoColor('.right-side::before', beforeOpacity),

			     	setPseudoColor('.left-right-side::after', afterOpacity),
			     	setPseudoColor('.left-right-side::before', beforeOpacity),
		     		setPseudoColor(' .left-right-side#sub-wrapper::after', afterOpacity),
			     	setPseudoColor(' .left-right-side#sub-wrapper::before', beforeOpacity),

			    ].join('\n')
			  }}>
			</style>

			<div id={uid} className={overlayClass}>
				<div id="sub-wrapper" className={subOverlayClass}>
				    { children }
				</div>
			</div>

		</>
	);
}

export default Overlays;
