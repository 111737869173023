const mobileData = {
    pageTitle: 'Typography (mobile)',
    displayRow: {
      headerText: 'DISPLAYS',
      rowData: [
          {
            title: 'DISPLAY 1',
            props: [
                {propertyName: 'Font family', propertyValue: 'ITC Century Book'},
                {propertyName: 'Font size', propertyValue: '2.25em (36px)'},
            ],
            demo_text: 'The quick brown fox',
            demo_text_class: 'display-1'
          },
          {
            title: 'DISPLAY 2',
            props: [
                {propertyName: 'Font family', propertyValue: 'ITC Century Book'},
                {propertyName: 'Font size', propertyValue: '1.75em (28px)'},
            ],
            demo_text: 'The quick brown fox',
            demo_text_class: 'display-2'
          }
      ]
    },
    headingRow: {
      headerText: 'HEADINGS',
      rowData: [
        {
            title: 'HEADING 1',
            props: [
                {propertyName: 'Font family', propertyValue: 'ITC Century Book'},
                {propertyName: 'Font size', propertyValue: '2em (32px)'},
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.',
            demo_text_class: 'heading-1'
        },
        {
            title: 'HEADING 2',
            props: [
                {propertyName: 'Font family', propertyValue: 'Azo Sans Bold'},
                {propertyName: 'Font size', propertyValue: '1.25em (20px)'}
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.',
            demo_text_class: 'heading-2'
          },
          {
            title: 'HEADING 3',
            props: [
                {propertyName: 'Font family', propertyValue: 'ITC Century Book'},
                {propertyName: 'Font size', propertyValue: '1.5em (24px)'},
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.',
            demo_text_class: 'heading-3'
          },
          {
            title: 'HEADING 4',
            props: [
                {propertyName: 'Font family', propertyValue: 'Azo Sans Regular'},
                {propertyName: 'Font size', propertyValue: '1em (16px)'},
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.',
            demo_text_class: 'heading-4'
          },
          {
            title: 'HEADING 5',
            props: [
                {propertyName: 'Font family', propertyValue: 'Azo Sans Bold'},
                {propertyName: 'Font size', propertyValue: '1em (16px)'},
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.',
            demo_text_class: 'heading-5'
          }
      ]
    },
    paragraphRow: {
      headerText: 'PARAGRAPHS',
      rowData: [
        {
            title: 'BODY COPY',
            props: [
                {propertyName: 'Font family', propertyValue: 'Azo Sans Light'},
                {propertyName: 'Font size', propertyValue: '1em (16px)'},
                {propertyName: 'Line height', propertyValue: '1.5em (24px)'}
            ],
            demo_text: 'The quick brown fox jumps over the lazy dog.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
            demo_text_class: 'body-copy'
        },
        {
          title: 'SMALL COPY',
          props: [
              {propertyName: 'Font family', propertyValue: 'Azo Sans Light'},
              {propertyName: 'Font size', propertyValue: '.625em (10px)'},
              {propertyName: 'Line height', propertyValue: '1em (16px)'}
          ],
          demo_text: 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          demo_text_class: 'small-copy'
        }
      ]
    }
  }
export default mobileData;