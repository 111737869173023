import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import "./largeTitle.css";

const LargeTitle = ({ fields, params }) => {
  // just in case if there is no datasource item passed to this component
  if (!fields) {
    return null;
  }
  const {
    titleColor
  } = params;
  const tColor = titleColor ? '#' + titleColor : '';
  return (
    <div>
      <div className="large-title__title-container">
        <h1 
          className="large-title__title_text"
          style={{ color: `${tColor}` }}
        >
          <Text field={fields.heading}/>
        </h1>
      </div>
    </div>
  );
};

export default LargeTitle;
