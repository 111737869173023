import React from 'react';
import { NavLink, Link } from 'react-router-dom';

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

import './assets/404.css';


const NotFound = ({ context = { site: { name: '' }, language: '' } }) => (
  <div className="not-found-page">

    <div id="ppm-desktop-navigation-container">
        <nav  id="ppm-nav-desktop" className="larger-ppm-header">
          <ul>
	          <li id="ppm-nav-desktop-logo-container-li">
	            {/* Set ppm logo in nav to link to demo page in jss start mode */}
	            <NavLink to="/" exact={true}>
	                <img id="ppm-nav-desktop-logo" src="assets/ppmNotFoundLogo.svg" alt="PPM Logo"/>
	            </NavLink>
	          </li>
	         </ul>
        </nav>
     </div>
     <main id="ppm-main-website-content-container">
     	<div>
            <div className="top-msg-box">
            	<div className="top-msg-wrapper">
                	<div className="display-2 top-msg">We're Sorry!</div>
                </div>
            </div>

	     	<div className="text-wrapper">
	            <h2 className="one-column-rich-text__title">The page you are looking for cannot be found.</h2>
	            <p className="body-copy">This could be the result of the page being removed, the name being changed or the
	                page being temporarily unavailable.</p>
	            <h2 className="one-column-rich-text__title">Troubleshooting</h2>
	            <ul className="body-copy">
	                <li>If you spelled the URL manually, please double check the spelling</li>
	                <li>Click the logo above to go to our website’s home page, or use the main
	                    navigation to find the content in question</li>
	            </ul>
	        </div>
        </div>
     </main>
     <div id="jss-footer">
        <div className="buffer"></div>
        <div className="footer-content">
            <div className="buffer"></div>
            <div className="row footer-container">
                <div className="footer-logo col-6 order-1 col-sm-4 order-sm-1 col-md-3 order-md-1 col-lg-2 order-lg-1 col-xl-1 order-xl-1">
                    <img className="ppm-logo col-auto col-sm-auto col-md-auto col-lg-auto col-xl-auto" src="assets/ppmNotFoundLogo.svg" alt="PPM Logo" />
                </div>
            </div>
        </div>
      </div>

  </div>
);

export default NotFound;
