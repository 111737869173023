import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

import Overlays from './../Overlays/index';
import BackgroundImage from './../BackgroundImage/index';

import returnPaddingMultiplierStylesObj from '../../util/returnPaddingMultiplerStylesObj.js'

//util
import { canUseDom } from '../../util/canUseDom'

const mobileBreakPoint = 576 //TODO Move to config
const maxWidthNumber = 1440
const maxWidth = `${maxWidthNumber}px`
const imageComponentName = "IndividualImage"
const IMG_COMPONENT_MOBILE_MAX_HEIGHT = '40vh'; // applies only to component with image and no content

class TwoColumnLayout extends React.Component {
  constructor() {
    super()
    this.state = {
      mobileView: false,
      height: 'auto'
    }
    //attach an event listener to remove the gutter addition style and apply it based on non-mobile layout
    if (canUseDom) {
      window.addEventListener('resize', this.updateBasedOnWidth);
    }
    this.firstColRef = React.createRef();
    this.secondColRef = React.createRef();
    this.resizeBackgroundImageComponent = this.resizeBackgroundImageComponent.bind(this);
  }

  updateBasedOnWidth = () => {
    if (window.innerWidth < mobileBreakPoint) {
      this.setState({
        mobileView: true
      })
    } else {
      this.setState({
        mobileView: false
      })
    }
  }
  componentWillUnmount() {
    if (canUseDom) {
      window.removeEventListener('resize', this.updateBasedOnWidth);
      window.removeEventListener('resize', this.resizeBackgroundImageComponent);
    }
  }

  componentDidMount() {
    if (canUseDom) {
      this.updateBasedOnWidth();
    }
    this.resizeBackgroundImageComponent();
    window.addEventListener('resize', this.resizeBackgroundImageComponent)
  }

  resizeBackgroundImageComponent() {
    let node, height;
    if (this.leftSideHasImage()) {
      node = this.secondColRef.current;
    } else if (this.rightSideHasImage()) {
      node = this.firstColRef.current;
    }
    if (node) {
      height = node.clientHeight;
      this.setState({
        height
      });
    }
  }

  leftSideHasImage() {
    const { params } = this.props;
    const leftBackImage = params && params.leftBackgroundImage ? params.leftBackgroundImage : null,
      leftHasComponent = this.props.rendering.placeholders['ppm-two-column-left'] && this.props.rendering.placeholders['ppm-two-column-left'].length > 0 ? true : false,
      isLayoutBackground = !leftHasComponent && leftBackImage;
    return isLayoutBackground;
  }

  rightSideHasImage() {
    const { params } = this.props;
    const rightBackImage = params && params.rightBackgroundImage ? params.rightBackgroundImage : null,
      rightHasComponent = this.props.rendering.placeholders['ppm-two-column-right'] && this.props.rendering.placeholders['ppm-two-column-right'].length > 0 ? true : false,
      isLayoutBackground = !rightHasComponent && rightBackImage;
    return isLayoutBackground;
  }

  render() {
    const { props } = this
    const { params } = props;
    //setup the padding multiplier object
    const paddingStyle = returnPaddingMultiplierStylesObj(params, "twoColumnLayout")

    //set const for whether the component is full width or max width of 1440 px
    const componentWidthOption = params && params.layoutComponentWidthOption ? params.layoutComponentWidthOption : "";
    const useMax_1440_Width = componentWidthOption === "max-width-1440"

    //set up a const for the arrangement option of either half and half - one third - two third or two thirds and one third 
    //- these arrangements will only apply on desktop and tablet and will stack on mobile
    const twoColumnArrangementOption = params && params.layoutComponentTwoColumnArrangement ? params.layoutComponentTwoColumnArrangement : "";
    const mainBackgroundColor = params && params.mainBackgroundColor ? '#' + params.mainBackgroundColor : "";
    const leftColumnBackgroundColor = params && params.leftColumnBackgroundColor ? '#' + params.leftColumnBackgroundColor : "";
    const rightColumnBackgroundColor = params && params.rightColumnBackgroundColor ? '#' + params.rightColumnBackgroundColor : "";

    //set up the background color and styles for either full width of max 1440px
    let layoutComponentStyle
    if (useMax_1440_Width) {
      layoutComponentStyle = {
        backgroundColor: mainBackgroundColor,
        maxWidth: maxWidth,
        margin: "auto"
      }
    } else {
      layoutComponentStyle = {
        backgroundColor: mainBackgroundColor
      }
    }
    const ppmTwoColumnContainerMaxWidthStyle = {
      maxWidth: `${maxWidthNumber}px`,
      margin: "auto"
    }
    const leftColumnBackgroundColorStyle = {
      backgroundColor: leftColumnBackgroundColor
    }
    const rightColumnBackgroundColorStyle = {
      backgroundColor: rightColumnBackgroundColor
    }

    //set up classes for alignment proportion of columns
    let container_classToUseForTwoColumnArrangement
    switch (twoColumnArrangementOption) {
      case "half-and-half":
        container_classToUseForTwoColumnArrangement = "ppm-layout-component-two-column-container-half"
        break;
      case "one-third-two-thirds":
        container_classToUseForTwoColumnArrangement = "ppm-layout-component-two-column-container-one-two"
        break;
      case "two-thirds-one-third":
        container_classToUseForTwoColumnArrangement = "ppm-layout-component-two-column-container-two-one"
        break;
      default:
        container_classToUseForTwoColumnArrangement = "ppm-layout-component-two-column-container-half"
        break;
      //default is half and half
    }

    // LEFT SIDE
    // define back image
    const leftBackImage = params && params.leftBackgroundImage ? params.leftBackgroundImage : null;
    // define focal point
    const leftFocalPoint = params && params.leftFocalPoint ? params.leftFocalPoint : null;

    // RIGHT SIDE
    // define back image
    const rightBackImage = params && params.rightBackgroundImage ? params.rightBackgroundImage : null;
    // define focal point
    const rightFocalPoint = params && params.rightFocalPoint ? params.rightFocalPoint : null;

    //use original params to get values 
    const overlay = params && params.overlay ? params.overlay : null;
    const overlayColor = params && params.overlayColor ? params.overlayColor : null;

    //set up vars for overlay
    //default left and right to overlay and overlay color if those are provided
    const leftOverlay = params && params.leftOverlay ? params.leftOverlay : overlay;
    const leftOverlayColor = params && params.leftOverlayColor ? params.leftOverlayColor : overlayColor;
    const rightOverlay = params && params.rightOverlay ? params.rightOverlay : overlay;
    const rightOverlayColor = params && params.rightOverlayColor ? params.rightOverlayColor : overlayColor;

    //individual column left right color options
    const leftColumnLeftOverlayColor = params && params.leftColumnLeftOverlayColor ? params.leftColumnLeftOverlayColor : null;
    const leftColumnRightOverlayColor = params && params.leftColumnRightOverlayColor ? params.leftColumnRightOverlayColor : null;
    const rightColumnLeftOverlayColor = params && params.rightColumnLeftOverlayColor ? params.rightColumnLeftOverlayColor : null;
    const rightColumnRightOverlayColor = params && params.rightColumnRightOverlayColor ? params.rightColumnRightOverlayColor : null;

    //Gutter mulitplier

    const gutterPaddingAddition = params && params.gutterPaddingAddition ? params.gutterPaddingAddition : "zero";
    let leftColumnRightGutterPaddingStyle
    let rightColumnLeftGutterPaddingStyle
    const globalPaddingMultiplier = 15 //TODO Move to config
    if (gutterPaddingAddition === "zero") {
      //if user selects zero for gutter padding addition
      //set style objects to nothing
      leftColumnRightGutterPaddingStyle = { paddingRight: `0px` }
      rightColumnLeftGutterPaddingStyle = { paddingLeft: `0px` }
    } else {
      //split the gutter padding addition since it will be applied across two elements
      leftColumnRightGutterPaddingStyle = {
        paddingRight: `${gutterPaddingAddition / 2 * globalPaddingMultiplier}px`
      }
      rightColumnLeftGutterPaddingStyle = {
        paddingLeft: `${gutterPaddingAddition / 2 * globalPaddingMultiplier}px`
      }
    }
    const leftSideHasImage = this.leftSideHasImage(),
      rightSideHasImage = this.rightSideHasImage();
    const leftColumnBackgroundHeightStyle = {
      height: this.state.mobileView && leftSideHasImage ? this.state.height : 'auto',
      maxHeight: this.state.mobileView && leftSideHasImage ? IMG_COMPONENT_MOBILE_MAX_HEIGHT : 'none'
    }
    const rightColumnBackgroundHeightStyle = {
      height: this.state.mobileView && rightSideHasImage ? this.state.height : 'auto',
      maxHeight: this.state.mobileView && rightSideHasImage ? IMG_COMPONENT_MOBILE_MAX_HEIGHT : 'none'
    }
    return (
      <section className="ppm-two-column-layout-component-container" style={layoutComponentStyle}>
        <div style={ppmTwoColumnContainerMaxWidthStyle}>
          <div className={container_classToUseForTwoColumnArrangement}>
            <div style={{ ...leftColumnBackgroundColorStyle, ...leftColumnBackgroundHeightStyle }}>
              <BackgroundImage
                backgroundImage={leftBackImage}
                backgroundPositionDesktop={{
                  x: params && params.leftBackgroundImagePositionX ? params.leftBackgroundImagePositionX : undefined,
                  y: params && params.leftBackgroundImagePositionY ? params.leftBackgroundImagePositionY : undefined
                }}
                backgroundPositionTablet={{
                  x: params && params.leftBackgroundImageTabletPositionX ? params.leftBackgroundImageTabletPositionX : undefined,
                  y: params && params.leftBackgroundImageTabletPositionY ? params.leftBackgroundImageTabletPositionY : undefined
                }}
                backgroundPositionMobile={{
                  x: params && params.leftBackgroundImageMobilePositionX ? params.leftBackgroundImageMobilePositionX : undefined,
                  y: params && params.leftBackgroundImageMobilePositionY ? params.leftBackgroundImageMobilePositionY : undefined
                }}
                focalPoint={leftFocalPoint}
                routeData={props.routeData}>
                <Overlays
                  props={props}
                  children={props.children}
                  overlay={leftOverlay}
                  leftOverlayColor={leftColumnLeftOverlayColor}
                  rightOverlayColor={leftColumnRightOverlayColor}
                  overlayColor={leftOverlayColor}
                  overlayElement='ppm-two-column-left'
                  overlaySubElement='ppm-two-column-left'>
                  <div
                    ref={this.firstColRef}
                    id="ppm-two-column-left"
                    className={`ppm-layout-component-two-column-left`}>
                    <div className="container">
                      <div className="row" style={this.state.mobileView ? null : leftColumnRightGutterPaddingStyle}>
                        <div className="col-12" style={paddingStyle}>
                          <Placeholder name="ppm-two-column-left" {...props} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Overlays>
              </BackgroundImage>
            </div>
            <div style={{ ...rightColumnBackgroundColorStyle, ...rightColumnBackgroundHeightStyle }}>
              <BackgroundImage
                backgroundImage={rightBackImage}
                backgroundPositionDesktop={{
                  x: params && params.rightBackgroundImagePositionX ? params.rightBackgroundImagePositionX : undefined,
                  y: params && params.rightBackgroundImagePositionY ? params.rightBackgroundImagePositionY : undefined
                }}
                backgroundPositionTablet={{
                  x: params && params.rightBackgroundImageTabletPositionX ? params.rightBackgroundImageTabletPositionX : undefined,
                  y: params && params.rightBackgroundImageTabletPositionY ? params.rightBackgroundImageTabletPositionY : undefined
                }}
                backgroundPositionMobile={{
                  x: params && params.rightBackgroundImageMobilePositionX ? params.rightBackgroundImageMobilePositionX : undefined,
                  y: params && params.rightBackgroundImageMobilePositionY ? params.rightBackgroundImageMobilePositionY : undefined
                }}
                focalPoint={rightFocalPoint}
                routeData={props.routeData}>
                <Overlays
                  props={props}
                  children={props.children}
                  overlay={rightOverlay}
                  overlayColor={rightOverlayColor}
                  leftOverlayColor={rightColumnLeftOverlayColor}
                  rightOverlayColor={rightColumnRightOverlayColor}
                  overlayElement='ppm-two-column-right'
                  overlaySubElement='ppm-two-column-right'>
                  <div
                    ref={this.secondColRef}
                    id="ppm-two-column-right"
                    className={`ppm-layout-component-two-column-right`}>
                    <div className="container">
                      <div className="row" style={this.state.mobileView ? null : rightColumnLeftGutterPaddingStyle}>
                        <div className="col-12" style={paddingStyle}>
                          <Placeholder name="ppm-two-column-right" {...props} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Overlays>
              </BackgroundImage>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TwoColumnLayout;
