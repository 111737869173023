//util
import { canUseDom } from './canUseDom'

const globalBorderHeight = 1
const borderHeightReductionAmountForMobile = 2
const mobileBreakPoint = 576

const convertToBorderMultiplier = {
    'border-1': 1,
    'border-2': 2,
    'border-3': 3,
    'border-4': 4,
    'border-5': 5,
    'border-6': 6,
    'border-7': 7,
    'border-8': 8,
    'border-9': 9,
    'border-10': 10,
}

const returnBorderMultiplierStyleObj = (params) => {

    let windowIsBelowMobileBreakPoint = false
    if (canUseDom) {
        //check the size of the window
        let windowWidth = window.innerWidth
        //if smaller than mobile breakpoint - return styles without left right Border
        if (windowWidth < mobileBreakPoint) {
            windowIsBelowMobileBreakPoint = true
        }
    }
    let borderDefault = 0

    let applyTopBottomBorderReduction = false

    const borderMultiplier = params && params.borderMultiplier ? params.borderMultiplier : borderDefault;

    //return style object based on mobile breakpoint
    if (windowIsBelowMobileBreakPoint && applyTopBottomBorderReduction) {
        //for mobile reduce top bottom padding by half 
        return {
            borderBottom: handleZeroBorder(borderMultiplier, true)
        }
    } else {
        //only return left and right padding if window is above mobile breakpoint
        return {
            borderBottom: handleZeroBorder(borderMultiplier)
        }
    }
}

const handleZeroBorder = (borderHeightMultiplier, reduceBorderSizeByHalf = false) => {
    const borderMultiplierInt = convertToBorderMultiplier[borderHeightMultiplier];
    let multiplier = reduceBorderSizeByHalf && borderMultiplierInt > 1 ?
        parseInt(borderMultiplierInt, 10) / borderHeightReductionAmountForMobile :
        parseInt(borderMultiplierInt, 10);
    return `${globalBorderHeight * multiplier}px`
}

export default returnBorderMultiplierStyleObj