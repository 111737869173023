import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const StatisticsItem = (props) => {
  const statType = props.params && props.params.statType ? props.params.statType : ""
  const includeSuperScript = props.params && props.params.includeSuperScript ? props.params.includeSuperScript : ""
  const showSuperScriptField = includeSuperScript === "include-super-script"
  switch (statType) {
    case "currency":
      return renderCurrency(props, showSuperScriptField)
    case "percent":
      return renderPercent(props, showSuperScriptField)
    case "number":
      return renderNumber(props, showSuperScriptField)
   } 
}
const superScript = (props, showSuperScriptField) => {
  return showSuperScriptField ? 
    <span className="stats-item-number-superscript">
      <Text field={props.fields.superScript}></Text>
    </span> : null
}
const renderCurrency = (props, showSuperScriptField) => {
  return <div className="stats-item-container">
  <div className="stats-item-number">
      <span className="stats-item-number-currency">$</span>
      <Text field={props.fields.statNumber}/>
  </div>
  <div className="stats-item-label">
      <Text field={props.fields.statLabel}/>
      {superScript(props, showSuperScriptField)}
  </div>
</div>
}
const renderPercent = (props, showSuperScriptField) => {
  return <div className="stats-item-container">
    <div className="stats-item-number">
        <Text field={props.fields.statNumber}/>
        <span className="stats-item-number-percent">%</span>
    </div>
    <div className="stats-item-label">
        <Text field={props.fields.statLabel}/>
        {superScript(props, showSuperScriptField)}
    </div>
  </div>
}
const renderNumber = (props, showSuperScriptField) => {
  return <div className="stats-item-container">
    <div className="stats-item-number">
        <Text field={props.fields.statNumber}/>
    </div>
    <div className="stats-item-label">
        <Text field={props.fields.statLabel}/>
        {superScript(props, showSuperScriptField)}
    </div>
  </div>
}
export default StatisticsItem;