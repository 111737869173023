import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import $ from 'jquery'

const cnLookup = {
  firstLevelLinkDesktop: "firstLevelLinkDesktop",
  dropDownDiv: "ppm-drop-down-div top-105",
  secondLevelLink: "secondLevelLink",
  thirdLevelLink: "thirdLevelLink"
}

class PpmDeskTopNavigation extends React.Component{
  searchBarIsVisible = false
  resizeHeaderOnScroll = () => {
    if(typeof document === "undefined"){
      return false
    }
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    shrinkOn = 200,
    navContainer = document.getElementById("ppm-desktop-navigation-container"),
    headerEl = document.getElementById('ppm-nav-desktop'),
    dropDowns = document.getElementsByClassName('ppm-drop-down-div'),
    searchContainer = document.getElementsByClassName('ppm-nav-desktop-search-container-center-align-div')
    if(headerEl){
      if (distanceY > shrinkOn) {
        //add drop shadow to nav container on scroll
        navContainer.classList.add("ppm-desktop-nav-dropShadow");
        //apply classes for shrinking the header
        headerEl.classList.add("shrink-ppm-header");
        headerEl.classList.remove("larger-ppm-header");
        //change the padding on all the drop downs to they align with shrunk header
        for(let index = 0; index < dropDowns.length ; index++){
          dropDowns[index].classList.remove("top-105")
          dropDowns[index].classList.add("top-70")
          //change the top css property for the search bar so it resizes in case it's open
        }
        if (searchContainer[0])
          searchContainer[0].style.top = "70px"
      } else {
        //remove the drop shadow when nav is original size
        navContainer.classList.remove("ppm-desktop-nav-dropShadow");
        //remove shrink header class and add larger header class
        headerEl.classList.remove("shrink-ppm-header");
        headerEl.classList.add("larger-ppm-header");
        //resize padding for drop downs
        for(let index = 0; index < dropDowns.length ; index++){
          dropDowns[index].classList.add("top-105")
          dropDowns[index].classList.remove("top-70")
          //change the top css property for the search bar so it resizes in case it's open
        }
        if (searchContainer[0])
          searchContainer[0].style.top = "105px"
      }
      if(this.searchBarIsVisible && !this.props.onSearchResultsPage){
        //hide the search container if they scroll at all after opening it
        this.toggleSearchCrossImage()
        this.toggleVisibilityOfSearchContainer()
      }
    }
  }
  setUpNavigationBasedOnLocation = () => {
    if(this.props.canUseDom){
      //select the main website container for added padding to top
      var bodyContent = document.getElementById("ppm-main-website-content-container")
      
      //if on search results page show the search bar by default
      if(this.props.onSearchResultsPage){
        
        // change padding for website content
        bodyContent.style.paddingTop = "185px"
        this.toggleSearchCrossImage()
      
        //toggle search container and pass true so search term can be set
        this.toggleVisibilityOfSearchContainer(true)
      }else{
        //in case the user came from the search page check if search is visible
        if(this.searchBarIsVisible){
          //close search bar on any page if last page was search
          this.toggleSearchCrossImage()
          this.toggleVisibilityOfSearchContainer()
          //set var to reflect the change
          this.searchBarIsVisible = false
        }else{
          // change padding for website content if not on search page - search is collapsed
          bodyContent.style.paddingTop = "105px"
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    //set a var to rerender the navigation in case we're on a new page
    //to make sure to hide the search bar if it's been expanded
    let onNewPage = prevProps.currentPage !== this.props.currentPage
    
    //since render doesn't use state, exlicitly call set up method
    //if last page was on search results and now new page isn't search results
    if(onNewPage || 
      (prevProps.onSearchResultsPage && 
      prevProps.onSearchResultsPage !== this.props.onSearchResultsPage)){
      this.setUpNavigationBasedOnLocation()  
    }
  }
  componentDidMount(){
    this.setUpNavigationBasedOnLocation()
    this.bindClickHandlers()
  }
  bindClickHandlers = () => {
     //add resize header on scroll function for any page  
     window.addEventListener('scroll', this.resizeHeaderOnScroll.bind(this));
      
     //search button click handler
     $(".ppm-nav-desktop-search-container-button").on("click", () => {
       //submit the search term
       $("#ppm-nav-desktop-search-form").submit()
     })

     //set up click handler for search icon
     $("#ppm-desktop-search-cross-list-item").on("click", () => {
       this.toggleSearchCrossImage()
       this.toggleVisibilityOfSearchContainer()
     })
  }
  toggleSearchCrossImage = () => {
    let searchAndCrossListItem = $("#ppm-desktop-search-cross-list-item")
    let searchIcon = searchAndCrossListItem[0].childNodes[0]
    let crossIcon = searchAndCrossListItem[0].childNodes[1]
    if(searchIcon.hidden){
      searchIcon.hidden = false
      crossIcon.hidden = true 
    }else{
      searchIcon.hidden = true
      crossIcon.hidden = false
    }
  }
  toggleVisibilityOfSearchContainer = () => {
    let searchContainer = $(".ppm-nav-desktop-search-container")[0]
    let siteContent = $("#ppm-main-website-content-container")[0]
    if(this.searchBarIsVisible){
      searchContainer.style.opacity = "0"
      searchContainer.style.visibility = "hidden"
      searchContainer.zIndex = '-1'
      this.searchBarIsVisible = false
      siteContent.style.paddingTop = "105px"
      setTimeout(() => {
        //clear out the search value
        searchContainer.firstElementChild[0].value = ""
      }, 350)

    }else{
      if(this.props.onSearchResultsPage){
        //initialize search term to place in search bar incase it was manually removed from url
        let plusReplacesWithSpacesSearchTerm = ""
        if(window.location.search.indexOf('=') > -1){
          //replace + with spaces for user friendly display of search term
          plusReplacesWithSpacesSearchTerm = this.props.searchTermUtil()
        }
        // set the search term as the text in the search bar
        searchContainer.firstElementChild[0].value = plusReplacesWithSpacesSearchTerm
      }
      searchContainer.zIndex = '8999'
      //make the desktop search bar visible
      searchContainer.style.visibility = "visible"
      searchContainer.style.opacity = "1"
      //update searchbar visibility var
      this.searchBarIsVisible = true
      //add padding to the top of the site
      siteContent.style.paddingTop = "185px" 
      //set timeout to either put focus on the input or first add the search term and add focus
      setTimeout(() => {
        //put focus into the input of the search
        document.getElementById("ppm-nav-desktop-search-input").focus();
      }, 350)
    }
  }
  render(){
    const inJssCodeFirstMode = this.props.routeData.databaseName === "available-in-connected-mode"
    const searchPlaceholderText = this.props.fields.searchPlaceholderText.value ? this.props.fields.searchPlaceholderText.value : "Search"
    return (
      <React.Fragment>
      <div id="ppm-desktop-navigation-container">
        <nav  id="ppm-nav-desktop" className="larger-ppm-header">
          <ul>
          <li id="ppm-nav-desktop-logo-container-li">
            {/* Set ppm logo in nav to link to demo page in jss start mode */}
            <NavLink to="/" exact={true}>
            {
              this.props && this.props.fields && 
              this.props.fields.logo && this.props.fields.logo.value && this.props.fields.logo.value.src ? (
                <img id="ppm-nav-desktop-logo" src={this.props.fields.logo.value.src} alt="PPM Logo"/>
              ) : null
            }
            </NavLink>
          </li>
            {
              this.props.navigationData.navRoot.children.map((navItem, index) => (
                <FirstLevelNavItemDesktop key={index} navItem={navItem} {...this.props} />
              ))
            }
              {inJssCodeFirstMode ?
              <li className="ppm-first-level-desktop-list-item">
                  <NavLink exact={true} to="/demo" className={cnLookup.firstLevelLinkDesktop}>Demo</NavLink>
              </li> : 
            null}
            <li className="ppmSearchIcon" id="ppm-desktop-search-cross-list-item">
            {
              this.props && this.props.routeData && this.props.fields && 
              this.props.fields.searchIcon && this.props.fields.searchIcon.value && this.props.fields.searchIcon.value.src ? (
                <img id="ppm-nav-desktop-search-icon" src={this.props.fields.searchIcon.value.src} alt="PPM Search Icon"/>
              ) : null
            }
            {
              this.props && this.props.routeData && this.props.fields && 
              this.props.fields.xIcon && this.props.fields.xIcon.value && this.props.fields.xIcon.value.src ? (
                <img id="ppm-nav-desktop-cross-icon" src={this.props.fields.xIcon.value.src} alt="PPM Cross Icon" hidden/>
              ) : null
            }
            </li>
          </ul>
        </nav>
      </div>
      <div className="ppm-nav-desktop-search-container-center-align-div">
        <div className="ppm-nav-desktop-search-container">
          <form action="/search" id="ppm-nav-desktop-search-form">
              <input 
                  id="ppm-nav-desktop-search-input"
                  type="text" 
                  name="searchTerm" 
                  placeholder={searchPlaceholderText}>
              </input>
          </form>
          <div className="ppm-nav-desktop-search-container-button">
            <span id="ppm-nav-desktop-search-container-button-text">SEARCH</span>
            {
              this.props && this.props.routeData && this.props.fields && 
              this.props.fields.searchIconWhite && this.props.fields.searchIconWhite.value && this.props.fields.searchIconWhite.value.src ? (
                <img id="ppm-nav-desktop-search-icon-search-container-image" src={this.props.fields.searchIconWhite.value.src}></img>
              ) : null
            }
          </div>
        </div>
      </div>
      </React.Fragment>
      )
  } 
}


const FirstLevelNavItemDesktop = ({ navItem, inJssCodeFirstMode }) => {
  return navItem.children.length > 0 ? 
    navItem.visible.boolValue ?  <li className="ppm-first-level-desktop-list-item">
    <NavLink 
      exact={true} 
      to={navItem.targetLink.url} 
      className={cnLookup.firstLevelLinkDesktop}>
        {navItem.targetName.value}
    </NavLink> 
    <SecondLevelDropDownDivDesktop navItems={navItem.children} />
  </li> : null
    :
    navItem.visible.boolValue ? <li  className="ppm-first-level-desktop-list-item">
    <NavLink  
      exact={true} 
      to={navItem.targetLink.url} 
      className={cnLookup.firstLevelLinkDesktop}>
        {navItem.targetName.value}
    </NavLink> 
  </li> : null
};

const SecondLevelDropDownDivDesktop = ({navItems}) => (
  <ul className={cnLookup.dropDownDiv}>
    <SecondLevelNavListDesktop navItems={navItems}/>
  </ul>
)

const SecondLevelNavListDesktop = (props) => (
  props.navItems.map((navItem, index) => (
    navItem.children.length > 0 ?
    (
      navItem.visible.boolValue ? 
      <React.Fragment key={index}>
        <li className="ppm-second-level-desktop-list-item">
          <NavLink 
            exact={true}
            className={cnLookup.secondLevelLink} 
            to={navItem.targetLink.url}>
            {navItem.targetName.value}
          </NavLink>
        </li>
        <li>
          <ThirdLevelNavListDesktop navItems={navItem.children}/>
        </li>
      </React.Fragment> 
      : null 
      )
    :
    (
      navItem.visible.boolValue ? 
      <li className="ppm-second-level-desktop-list-item" key={index}>
        <NavLink 
          exact={true}
          className={cnLookup.secondLevelLink} 
          to={navItem.targetLink.url}>
          {navItem.targetName.value}
        </NavLink>
      </li>
      : null
    )  
  ))
)

const ThirdLevelNavListDesktop = (props) => (
props.navItems.map((navItem, index) => (
  navItem.visible.boolValue ? 
  <NavLink 
    exact={true}
    className={cnLookup.thirdLevelLink} 
    key={index} 
    to={navItem.targetLink.url}>
    {navItem.targetName.value}
  </NavLink>
  : null
))
)

export default PpmDeskTopNavigation